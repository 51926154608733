import React from "react";
import { Box, Grid, Typography, Container, Button } from "@material-ui/core";
import { CSVLink } from "react-csv";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from "react";
import Moment from "moment";

import Airtable from "airtable";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_KEY,
});

const AirtableBase = Airtable.base("appUs02XwDJaGh6w2");

const CapitalizeWords = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default () => {
  const [From, setFrom] = useState(new Date());
  const [To, setTo] = useState(new Date());

  const [Data, setData] = useState([]);
  const [Coloumn] = useState([
    {
      key: "cell1",
      label: "Code",
    },
    {
      key: "cell2",
      label: "First name",
    },
    {
      key: "cell3",
      label: "Last name",
    },
    {
      key: "cell4",
      label: "Email",
    },
    {
      key: "cell5",
      label: "Phone",
    },
    {
      key: "cell6",
      label: "Interviewer",
    },
    {
      key: "cell7",
      label: "Agent",
    },
    {
      key: "cell8",
      label: "Date",
    },
    {
      key: "cell9",
      label: "Time",
    },
    {
      key: "cell10",
      label: "Partner",
    },
    {
      key: "cell11",
      label: "Course",
    },
    {
      key: "cell12",
      label: "Campus",
    },
    {
      key: "cell13",
      label: "Interviewer Recommedation",
    },
    {
      key: "cell14",
      label: "Approved by Management",
    },
    {
      key: "cell15",
      label: "PQP Group",
    },
    {
      key: "cell16",
      label: "PQP feedback",
    },
  ]);

  const downloadData = () => {
    setData([]);
    const students = [];
    AirtableBase("students")
      .select({
        filterByFormula: `AND({confirmed}, {marketeer email}='${localStorage.getItem("MarketeerEmail")}', {date}>'${Moment(From).format(
          "YYYY-MM-DD"
        )}', {date}<'${Moment(To).add(1, "days").format("YYYY-MM-DD")}')`,
        sort: [
          { field: "date", direction: "asc" },
          { field: "interview time", direction: "asc" },
        ],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(async (record) => {
            let cell1 = new Date(record.get("date"));
            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            students.push({
              cell1: record.get("code"),
              cell2: CapitalizeWords(record.get("first name")),
              cell3: CapitalizeWords(record.get("last name")),
              cell4: record.get("email"),
              cell5: record.get("phone"),
              cell6: record.get("interviewer name")[0],
              cell7: record.get("agent"),
              cell8: cell1.getDate() + "-" + month[cell1.getMonth()],
              cell9: record.get("interview time"),
              cell10: record.get("partner name")[0],
              cell11: record.get("programme name")[0],
              cell12: record.get("location name")[0],
              cell13: record.get("feedback"),
              cell14: record.get("Approved by TA"),
              cell15: record.get("group name"),
              cell16: record.get("pqp feedback") ? record.get("pqp feedback") : "",
            });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          setData(students);
        }
      );
  };

  return (
    <Box mx="20px" my="84px">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Download Reports</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={From}
                onChange={(date) => {
                  setFrom(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="From"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={To}
                onChange={(date) => {
                  setTo(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="To"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="outlined" color="secondary" size="large" onClick={downloadData}>
              Generate Report
            </Button>
          </Grid>
          {Data.length > 0 ? (
            <Grid item xs={12} sm={5}>
              <CSVLink data={Data} headers={Coloumn} filename={"report.csv"} target="_blank">
                <Button variant="outlined" color="secondary" size="large">
                  Download Report
                </Button>
              </CSVLink>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </Box>
  );
};
