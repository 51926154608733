import React from "react";
import { Box, Grid, Typography, Container, Button } from "@material-ui/core";
import { CSVLink } from "react-csv";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from "react";
import Moment from "moment";

import AirtableBase from "../airtable";

const CapitalizeWords = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default () => {
  const [From, setFrom] = useState(new Date());
  const [To, setTo] = useState(new Date());

  const [Data, setData] = useState([]);
  const [Coloumn] = useState([
    {
      key: "cell1",
      label: "Campus",
    },
    {
      key: "cell2",
      label: "Partner",
    },
    {
      key: "cell3",
      label: "Code",
    },
    {
      key: "cell4",
      label: "Batch",
    },
    {
      key: "cell5",
      label: "Approved by Management",
    },
    {
      key: "cell6",
      label: "Interviewer Recommedation",
    },
    {
      key: "cell7",
      label: "ROW",
    },
    {
      key: "cell8",
      label: "PQP Group",
    },
    {
      key: "cell9",
      label: "Submission",
    },
    {
      key: "cell10",
      label: "Update",
    },
    {
      key: "cell11",
      label: "First name",
    },
    {
      key: "cell12",
      label: "Last name",
    },
    {
      key: "cell13",
      label: "Email",
    },
    {
      key: "cell14",
      label: "Phone",
    },
    {
      key: "cell15",
      label: "Marketer",
    },
    {
      key: "cell16",
      label: "Agent",
    },
    {
      key: "cell17",
      label: "Course",
    },
    {
      key: "cell18",
      label: "Interviewer",
    },
    {
      key: "cell19",
      label: "Date",
    },
    {
      key: "cell20",
      label: "Time",
    },
    {
      key: "cell21",
      label: "Comments",
    },
    {
      key: "cell22",
      label: "Accepted/Rejected for PQP",
    },
    {
      key: "cell23",
      label: "Student comments",
    },
  ]);

  const downloadData = () => {
    setData([]);
    const students = [];
    AirtableBase("students")
      .select({
        filterByFormula: `AND({confirmed}, {date}>'${Moment(From).format("YYYY-MM-DD")}', {date}<'${Moment(To)
          .add(1, "days")
          .format("YYYY-MM-DD")}')`,
        sort: [
          { field: "date", direction: "asc" },
          { field: "interview time", direction: "asc" },
        ],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(async (record) => {
            let cell1 = new Date(record.get("date"));
            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const pqpAcceptance = record.get("accepted") ? record.get("accepted") : record.get("rejected") ? record.get("rejected") : "";
            students.push({
              cell1: record.get("location name")[0],
              cell2: record.get("partner name")[0],
              cell3: record.get("code"),
              cell4: record.get("batch"),
              cell5: record.get("Approved by TA"),
              cell6: record.get("feedback"),
              cell7: record.get("number"),
              cell8: record.get("group name"),
              cell9: record.get("submitted to university"),
              cell10: record.get("updates"),
              cell11: CapitalizeWords(record.get("first name")),
              cell12: CapitalizeWords(record.get("last name")),
              cell13: record.get("email"),
              cell14: record.get("phone"),
              cell15: record.get("marketeer name"),
              cell16: record.get("agent"),
              cell17: record.get("programme name")[0],
              cell18: record.get("interviewer name")[0],
              cell19: cell1.getDate() + "-" + month[cell1.getMonth()],
              cell20: record.get("interview time"),
              cell21: record.get("comments"),
              cell22: pqpAcceptance,
              cell23: record.get("student_pqp_comments"),
            });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          setData(students);
        }
      );
  };

  return (
    <Box mx="20px" my="84px">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Download Reports</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={From}
                onChange={(date) => {
                  setFrom(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="From"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={To}
                onChange={(date) => {
                  setTo(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="To"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="outlined" color="secondary" size="large" onClick={downloadData}>
              Generate Report
            </Button>
          </Grid>
          {Data.length > 0 ? (
            <Grid item xs={12} sm={5}>
              <CSVLink data={Data} headers={Coloumn} filename={"report.csv"} target="_blank">
                <Button variant="outlined" color="secondary" size="large">
                  Download Report
                </Button>
              </CSVLink>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </Box>
  );
};
