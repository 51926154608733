import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  Paper,
} from "@material-ui/core";
import AirtableBase from "../airtable";
import MyTable from "airtable";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Moment from "moment";
import axios from "axios";

const FeedbackComponent = ({
  Interviewer,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
  setBackDropOpen,
}) => {
  const NormalizePhone = (phone) => {
    phone = phone.replace(/[^0-9]/g, "");
    const length = phone.length;
    if (length === 10) {
      return "+44" + phone;
    }
    if (length === 11) {
      return "+44" + phone.slice(-10);
    }
    return phone;
  };
  const { student } = useParams();

  const [Student, setStudent] = useState({});
  const [InterviewEnglishTest, setInterviewEnglishTest] = useState([]);
  const [RetakeEnglishTest, setRetakeEnglishTest] = useState([]);

  const [EmailAddress, setEmailAddress] = useState("");

  const [PreviousFeedback, setPreviousFeedback] = useState([]);
  const [SeptemberFeedback, setSeptemberFeedback] = useState([]);
  const [TwentyTwentyOneFeedback, setTwentyTwentyOneFeedback] = useState([]);

  const [StudentRebook, setStudentRebook] = useState("");
  const [StudentRebookDetails, setStudentRebookDetails] = useState({});

  const [PQPGroup, setPQPGroup] = useState([]);

  const [Feedback, setFeedback] = useState({
    Action: "",
    Comments: "",
    Commitment: "",
    MaturityAndStability: "",
    QualityOfSpokenEnglish: "",
    QualityOfWrittenEnglish: "",
    Narrative: "",
    Below25YearsOfAge: "",
    Age: "",
    FinalGrade: "",
    NumberOfChildren: "",
    JobTitle: "",
    Salary: "",
    Ethnicity: "",
    MaritalStatus: "",
    ClassPreference: "",
    Employment: "",
    pqp_group: "",
    pqp_email: false,
    eu_settlement: "",
    eu_settlement_date: "",
    documents: "",
  });

  const [ErrorFeedback, setErrorFeedback] = useState({
    Action: false,
    Comments: false,
    Commitment: false,
    MaturityAndStability: false,
    QualityOfSpokenEnglish: false,
    QualityOfWrittenEnglish: false,
    Narrative: false,
    Below25YearsOfAge: false,
    Age: false,
    FinalGrade: false,
    NumberOfChildren: false,
    JobTitle: false,
    Salary: false,
    Ethnicity: false,
    MaritalStatus: false,
    ClassPreference: false,
    Employment: false,
    pqp_group: false,
    pqp_email: false,
    eu_settlement: false,
    eu_settlement_date: false,
  });

  useEffect(() => {
    AirtableBase("groups")
      .select({
        view: "Grid view",
        filterByFormula: `AND({Date}>'${Moment().format(
          "YYYY-MM-DD"
        )}', {Date}<'${Moment().add(5, "days").format("YYYY-MM-DD")}')`,
      })
      .firstPage(function (err, records) {
        if (err) console.error(err);
        setPQPGroup(records);
      });
  }, []);

  useEffect(() => {
    AirtableBase("students")
      .select({
        view: "Grid view",
        filterByFormula: `({email_address}='${EmailAddress}')`,
      })
      .firstPage(function (err, records) {
        console.log(records);
        if (err) console.error(err);
        setPreviousFeedback(records);
      });
  }, [EmailAddress]);

  useEffect(() => {
    MyTable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_KEY,
    });

    const base = MyTable.base("appp3aXeqwy9UUzH1");
    base("students")
      .select({
        view: "Grid view",
        filterByFormula: `({email_address}='${EmailAddress}')`,
      })
      .firstPage(function (err, records) {
        if (err) console.error(err);
        setSeptemberFeedback(records);
      });
  }, [EmailAddress]);

  useEffect(() => {
    MyTable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_KEY,
    });

    const base = MyTable.base("appUs02XwDJaGh6w2");
    base("students")
      .select({
        view: "Grid view",
        filterByFormula: `({email_address}='${EmailAddress}')`,
      })
      .firstPage(function (err, records) {
        if (err) console.error(err);
        setTwentyTwentyOneFeedback(records);
      });
  }, [EmailAddress]);

  useEffect(() => {
    MyTable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_KEY,
    });
    const base = MyTable.base("appB9VesVKYc2nRiH");

    base("interview")
      .select({
        view: "Grid view",
        filterByFormula: `({email}='${EmailAddress}')`,
      })
      .firstPage(function (err, records) {
        if (err) console.error(err);
        setInterviewEnglishTest(records);
      });

    base("Retake")
      .select({
        view: "Grid view",
        filterByFormula: `({email}='${EmailAddress}')`,
      })
      .firstPage(function (err, records) {
        if (err) console.error(err);
        setRetakeEnglishTest(records);
      });
  }, [EmailAddress]);

  useEffect(() => {
    AirtableBase("students").find(student, async (err, record) => {
      if (err) console.log(err);

      setEmailAddress(record.fields.email_address);

      if ("Old Rebook Ref" in record.fields) {
        setStudentRebook(record.fields["Old Rebook Ref"][0]);
      }

      if ("feedback" in record.fields) {
        HandleSetState(setFeedback, "Action", record.fields.feedback);
      }
      if ("comments" in record.fields) {
        HandleSetState(setFeedback, "Comments", record.fields.comments);
      }
      if ("Commitment" in record.fields) {
        HandleSetState(setFeedback, "Commitment", record.fields.Commitment);
      }
      if ("MaturityAndStability" in record.fields) {
        HandleSetState(
          setFeedback,
          "MaturityAndStability",
          record.fields.MaturityAndStability
        );
      }
      if ("QualityOfSpokenEnglish" in record.fields) {
        HandleSetState(
          setFeedback,
          "QualityOfSpokenEnglish",
          record.fields.QualityOfSpokenEnglish
        );
      }
      if ("QualityOfWrittenEnglish" in record.fields) {
        HandleSetState(
          setFeedback,
          "QualityOfWrittenEnglish",
          record.fields.QualityOfWrittenEnglish
        );
      }
      if ("Narrative" in record.fields) {
        HandleSetState(setFeedback, "Narrative", record.fields.Narrative);
      }
      if ("Below25YearsOfAge" in record.fields) {
        HandleSetState(
          setFeedback,
          "Below25YearsOfAge",
          record.fields.Below25YearsOfAge
        );
      }
      if ("Age" in record.fields) {
        HandleSetState(setFeedback, "Age", record.fields.Age);
      }
      if ("FinalGrade" in record.fields) {
        HandleSetState(setFeedback, "FinalGrade", record.fields.FinalGrade);
      }
      if ("NumberOfChildren" in record.fields) {
        HandleSetState(
          setFeedback,
          "NumberOfChildren",
          record.fields.NumberOfChildren
        );
      }
      if ("JobTitle" in record.fields) {
        HandleSetState(setFeedback, "JobTitle", record.fields.JobTitle);
      }
      if ("Salary" in record.fields) {
        HandleSetState(setFeedback, "Salary", record.fields.Salary);
      }
      if ("Ethnicity" in record.fields) {
        HandleSetState(setFeedback, "Ethnicity", record.fields.Ethnicity);
      }
      if ("MaritalStatus" in record.fields) {
        HandleSetState(
          setFeedback,
          "MaritalStatus",
          record.fields.MaritalStatus
        );
      }
      if ("ClassPreference" in record.fields) {
        HandleSetState(
          setFeedback,
          "ClassPreference",
          record.fields.ClassPreference
        );
      }
      if ("Employment" in record.fields) {
        HandleSetState(setFeedback, "Employment", record.fields.Employment);
      }
      if ("pqp group" in record.fields) {
        HandleSetState(setFeedback, "pqp_group", record.fields["pqp group"]);
      }
      if ("pqp_email" in record.fields) {
        HandleSetState(setFeedback, "pqp_email", record.fields.pqp_email);
      }
      if ("eu_settlement" in record.fields) {
        HandleSetState(
          setFeedback,
          "eu_settlement",
          record.fields.eu_settlement
        );
      }
      if ("eu_settlement_date" in record.fields) {
        HandleSetState(
          setFeedback,
          "eu_settlement_date",
          record.fields.eu_settlement_date
        );
      }
      if ("documents" in record.fields) {
        HandleSetState(setFeedback, "documents", record.fields.documents);
      }
      setStudent(record.fields);
    });
  }, [student]);

  useEffect(() => {
    if (StudentRebook) {
      AirtableBase("students").find(StudentRebook, async (err, record) => {
        setStudentRebookDetails(record.fields);
      });
    }
  }, [StudentRebook]);

  const HandleValidation = async (field, form) => {
    let flag = 0;

    if (field === "Action" || form) {
      if (Feedback.Action === "") {
        flag++;
        HandleSetState(setErrorFeedback, "Action", true);
      } else {
        HandleSetState(setErrorFeedback, "Action", false);
      }
    }

    if (field === "Comments" || form) {
      if (Feedback.Comments === "") {
        flag++;
        HandleSetState(setErrorFeedback, "Comments", true);
      } else {
        HandleSetState(setErrorFeedback, "Comments", false);
      }
    }

    return flag;
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const flag = await HandleValidation("", true);

    if (flag === 0) {
      const fields = {
        feedback: Feedback.Action,
        comments: Feedback.Comments,
        interviewed: true,
        Commitment: Feedback.Commitment,
        MaturityAndStability: Feedback.MaturityAndStability,
        QualityOfSpokenEnglish: Feedback.QualityOfSpokenEnglish,
        QualityOfWrittenEnglish: Feedback.QualityOfWrittenEnglish,
        Narrative: Feedback.Narrative,
        Below25YearsOfAge: Feedback.Below25YearsOfAge,
        Age: Feedback.Age,
        FinalGrade: Feedback.FinalGrade,
        NumberOfChildren: Feedback.NumberOfChildren,
        JobTitle: Feedback.JobTitle,
        Salary: Feedback.Salary,
        Ethnicity: Feedback.Ethnicity,
        MaritalStatus: Feedback.MaritalStatus,
        ClassPreference: Feedback.ClassPreference,
        Employment: Feedback.Employment,
        eu_settlement: Feedback.eu_settlement,
        eu_settlement_date: Feedback.eu_settlement_date,
      };

      if (Feedback.Action === "Approved") {
        fields["Approved by TA"] = true;
        fields["Rejected by TA"] = false;
      } else if (
        Feedback.Action === "Rejected" ||
        Feedback.Action === "Imposter"
      ) {
        fields["Approved by TA"] = false;
        fields["Rejected by TA"] = true;
      } else {
        fields["Approved by TA"] = false;
        fields["Rejected by TA"] = false;
      }

      AirtableBase("students").update(
        [
          {
            id: student,
            fields: fields,
          },
        ],
        (error, records) => {
          setAlertMessage("Feedback successfully saved");
          setAlertType("success");
          setAlertOpen(true);
        }
      );
    }
  };

  const HandleChange = (e) => {
    HandleSetState(setFeedback, e.target.name, e.target.value);
  };
  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleSendApproval = async (data) => {
    setBackDropOpen(true);
    const html = `Dear <strong>${Student["Full name"]}</strong>,<br><br>We are delighted to confirm that you have been successful in the interview for Admission with the London School of Commerce, and are now invited to attend and complete the Pre-Qualifying Programme Assessment.<br><br>Completion of the academic assessments is compulsory.<br><br>Please click on the link below to access the PQP Portal and the details of your zoom session.<br><br>Link: <strong><a href="https://www.pre.lsclondon.co.uk">www.pre.lsclondon.co.uk</a></strong><br><br>Starting on: <strong>${Student["pqp starting"]}</strong><br><br>Please ensure that your camera and audio are activated. <br><br>Please note that all classes are recorded for training and monitoring purposes.<br><br>Should you have any further queries, kindly contact our team via email at: <a href="mailto:${Student["partner email"]}">${Student["partner email"]}</a><br><br>Best wishes,<br><br>Admissions Department`;
    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
      {
        message: {
          to: Student["email"],
          name: "LSC Interviews",
          from: "interviews@lsclondon.co.uk",
          subject: "Invitation for your Pre-Qualifying Programme Assessment",
          body: html,
        },
      }
    );
    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/sms",
      {
        messages: [
          {
            source_address: "LSC",
            destination_address: NormalizePhone(Student["phone"]),
            content: `Dear ${Student["Full name"]},\n\nWe are delighted to confirm that you have been successful in the interview for Admission with the London School of Commerce, and are now invited to attend and complete the Pre-Qualifying Programme Assessment.\n\nCompletion of the academic assessments is compulsory.\n\nPlease click on the link below to access the PQP Portal and the details of your zoom session.\n\nwww.pre.lsclondon.co.uk\n\nStarting on: ${Student["pqp starting"]}\n\nPlease ensure that your camera and audio are activated. You may also need to download the ‘Zoom’ app/software, if you do not already have it installed.\n\nPlease note, that all classes are recorded for training and monitoring purposes.\n\nShould you have any further queries, kindly contact our team via email at: ${Student["partner email"]}`,
          },
        ],
      }
    );
    AirtableBase("students").update(
      [
        {
          id: student,
          fields: {
            pqp_email: true,
          },
        },
      ],
      (error, record) => {
        setBackDropOpen(false);
        setAlertMessage("Email sent successfully");
        setAlertType("success");
        setAlertOpen(true);
      }
    );
  };

  return (
    <Box mx="0" my="100px">
      <Container maxWidth="lg">
        <Typography variant="h5">
          Feedback for {Student["first name"]} {Student["last name"]}
        </Typography>
        <br></br>
        <br></br>
        <form onSubmit={HandleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <TextField
                name="Commitment"
                color="secondary"
                label="Commitment (100)"
                value={Feedback.Commitment}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.Commitment}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="MaturityAndStability"
                color="secondary"
                label="Maturity &amp; stability (100)"
                value={Feedback.MaturityAndStability}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.MaturityAndStability}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="QualityOfSpokenEnglish"
                color="secondary"
                label="Quality of spoken English (100)"
                value={Feedback.QualityOfSpokenEnglish}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.QualityOfSpokenEnglish}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                name="QualityOfWrittenEnglish"
                color="secondary"
                label="Quality of written English (100)"
                value={Feedback.QualityOfWrittenEnglish}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.QualityOfWrittenEnglish}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="Narrative"
                color="secondary"
                label="Narrative (100)"
                value={Feedback.Narrative}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.Narrative}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.Below25YearsOfAge}
              >
                <InputLabel id="Below25YearsOfAge-label">
                  Below 25 years of age?
                </InputLabel>
                <Select
                  labelId="Below25YearsOfAge-label"
                  name="Below25YearsOfAge"
                  value={Feedback.Below25YearsOfAge}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="Below 25 years of age?"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="True">Yes</MenuItem>
                  <MenuItem value="False">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                name="Age"
                color="secondary"
                label="Age"
                value={Feedback.Age}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.Age}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                name="NumberOfChildren"
                color="secondary"
                label="Number of Children"
                value={Feedback.NumberOfChildren}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.NumberOfChildren}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="JobTitle"
                color="secondary"
                label="Job Title"
                value={Feedback.JobTitle}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.JobTitle}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="Salary"
                color="secondary"
                label="Salary"
                value={Feedback.Salary}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.Salary}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.Salary}
              >
                <InputLabel id="Salary-label">Income Level</InputLabel>
                <Select
                  labelId="Salary-label"
                  name="Salary"
                  value={Feedback.Salary}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="Salary"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="Upto 10K">Upto 10K</MenuItem>
                  <MenuItem value="10k - 20k">10k - 20k</MenuItem>
                  <MenuItem value="20k - 30k">20k - 30k</MenuItem>
                  <MenuItem value="30k - 40k">30k - 40k</MenuItem>
                  <MenuItem value="40k - 50k">40k - 50k</MenuItem>
                  <MenuItem value="50k+">50k+</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="Ethnicity"
                color="secondary"
                label="Ethnicity"
                value={Feedback.Ethnicity}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.Ethnicity}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />

              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.Ethnicity}
              >
                <InputLabel id="Ethnicity-label">Ethnicity</InputLabel>
                <Select
                  labelId="Ethnicity-label"
                  name="Ethnicity"
                  value={Feedback.Ethnicity}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="Ethnicity"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="White">White</MenuItem>
                  <MenuItem value="Mixed / Multiple ethnic groups">
                    Mixed / Multiple ethnic groups
                  </MenuItem>
                  <MenuItem value="Eastern European">Eastern European</MenuItem>
                  <MenuItem value="Asian / Asian British">
                    Asian / Asian British
                  </MenuItem>
                  <MenuItem value="Black / African / Caribbean / Black British">
                    Black / African / Caribbean / Black British
                  </MenuItem>
                  <MenuItem value="Other ethnic group">
                    Other ethnic group
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.FinalGrade}
              >
                <InputLabel id="FinalGrade-label">Final Grade</InputLabel>
                <Select
                  labelId="FinalGrade-label"
                  name="FinalGrade"
                  value={Feedback.FinalGrade}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="Final Grade"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                  <MenuItem value="F">F</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.MaritalStatus}
              >
                <InputLabel id="MaritalStatus-label">
                  Select the Marital Status
                </InputLabel>
                <Select
                  labelId="MaritalStatus-label"
                  name="MaritalStatus"
                  value={Feedback.MaritalStatus}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="MaritalStatus"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Single">Single</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.ClassPreference}
              >
                <InputLabel id="ClassPreference-label">
                  Timetable Preference
                </InputLabel>
                <Select
                  labelId="ClassPreference-label"
                  name="ClassPreference"
                  value={Feedback.ClassPreference}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="ClassPreference"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="Day">Day</MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                  <MenuItem value="Any">Any</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.Employment}
              >
                <InputLabel id="Employment-label">Employment</InputLabel>
                <Select
                  labelId="Employment-label"
                  name="Employment"
                  value={Feedback.Employment}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="Employment"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="UnEmployed">UnEmployed</MenuItem>
                  <MenuItem value="Structured Employment">
                    Structured Employment
                  </MenuItem>
                  <MenuItem value="Casual Employment">
                    Casual Employment
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.eu_settlement}
              >
                <InputLabel id="eu_settlement-label">EU Settlement</InputLabel>
                <Select
                  labelId="eu_settlement-label"
                  name="eu_settlement"
                  value={Feedback.eu_settlement}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="EU Settlement"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="Settled">Settled</MenuItem>
                  <MenuItem value="Pre-Settled">Pre-Settled</MenuItem>
                  <MenuItem value="Applied to the EU Settlement Scheme">
                    Applied to the EU Settlement Scheme
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="eu_settlement_date"
                color="secondary"
                label="EU Settlement Date"
                value={Feedback.eu_settlement_date}
                fullWidth
                variant="outlined"
                error={ErrorFeedback.eu_settlement_date}
                onChange={HandleChange}
                onBlur={HandleBlur}
              />
            </Grid>
            {Feedback.documents ? (
              <Grid item xs={12} sm={3}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    window.open(Feedback.documents, "_blank");
                  }}
                >
                  View ID Document
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            {/**Feedback.Action === "Approved" ? (
              <>
                <Grid item sx={12} sm={3}>
                  <FormControl variant="outlined" fullWidth error={ErrorFeedback.pqp_group}>
                    <InputLabel id="pqp_group-label">PQP Group</InputLabel>
                    <Select
                      labelId="pqp_group-label"
                      name="pqp_group"
                      value={Feedback.pqp_group}
                      onChange={HandleChange}
                      onBlur={HandleBlur}
                      label="PQP Group"
                    >
                      <MenuItem value="">&nbsp;</MenuItem>
                      {PQPGroup.map((data, key) => {
                        return (
                          <MenuItem key={key} value={data.id}>
                            {data.fields.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Button type="button" variant="contained" color="secondary" style={{ width: "100%", height: "100%" }} onClick={HandleSendApproval}>
                    {Student.pqp_email ? "Re-" : ""}Send PQP Invitation
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )**/}
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                fullWidth
                error={ErrorFeedback.Action}
              >
                <InputLabel id="action-label">Select the Recommend</InputLabel>
                <Select
                  labelId="action-label"
                  name="Action"
                  value={Feedback.Action}
                  onChange={HandleChange}
                  onBlur={HandleBlur}
                  label="Campus"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Invalid ID">Invalid ID</MenuItem>
                  <MenuItem value="Imposter">Imposter</MenuItem>
                  <MenuItem value="Rejected and Deferred">
                    Rejected and Deferred
                  </MenuItem>
                  <MenuItem value="Review">Review</MenuItem>
                  <MenuItem value="Recall">Recall</MenuItem>
                  <MenuItem value="No Show">No Show</MenuItem>
                  <MenuItem value="Deferral">Deferral</MenuItem>
                  <MenuItem value="Awaiting English Test">
                    Awaiting English Test
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="Comments"
                color="secondary"
                label="Comments"
                value={Feedback.Comments}
                fullWidth
                helperText="Please enter comments for students"
                variant="outlined"
                error={ErrorFeedback.Comments}
                onChange={HandleChange}
                onBlur={HandleBlur}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <br />
              <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>
              <br />
            </Grid>
          </Grid>
        </form>
        <br></br>
        <br></br>
        {PreviousFeedback.map((records, index) => {
          return (
            <>
              <Paper>
                <Box p={4}>
                  <Typography variant="h5" gutterBottom>
                    Status: <strong>{records.fields["feedback"]}</strong>{" "}
                    Student interviewed by{" "}
                    <strong>{records.fields["interviewer name"][0]}</strong> on{" "}
                    <strong>{records.fields["date"]}</strong>
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Student applied for{" "}
                    <strong>{records.fields["programme name"][0]}</strong> under{" "}
                    <strong>{records.fields["partner name"][0]}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Marketeer name:{" "}
                    <strong>{records.fields["marketeer name"][0]}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Comment: <strong>{records.fields["comments"]}</strong>
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    PQP Feedback:{" "}
                    <strong>{records.fields["pqp feedback"]}</strong>
                  </Typography>
                </Box>
              </Paper>
              <br />
            </>
          );
        })}
        <br></br>
        {TwentyTwentyOneFeedback.length > 0 ? (
          TwentyTwentyOneFeedback.map((records, index) => {
            return (
              <>
                <Paper>
                  <Box p={4}>
                    <Typography variant="h5" gutterBottom>
                      Status: <strong>{records.fields["feedback"]}</strong>{" "}
                      Student interviewed by{" "}
                      <strong>{records.fields["interviewer name"][0]}</strong>{" "}
                      on <strong>{records.fields["date"]}</strong>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      Student applied for{" "}
                      <strong>{records.fields["programme name"][0]}</strong>{" "}
                      under <strong>{records.fields["partner name"][0]}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Marketeer name:{" "}
                      <strong>{records.fields["marketeer name"][0]}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Comment: <strong>{records.fields["comments"]}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      PQP Feedback:{" "}
                      <strong>{records.fields["pqp feedback"]}</strong>
                    </Typography>
                  </Box>
                </Paper>
                <br />
              </>
            );
          })
        ) : (
          <></>
        )}
        {SeptemberFeedback.length > 0 ? (
          SeptemberFeedback.map((records, index) => {
            return (
              <>
                <Paper>
                  <Box p={4}>
                    <Typography variant="h5" gutterBottom>
                      Status: <strong>{records.fields["feedback"]}</strong>{" "}
                      Student interviewed by{" "}
                      <strong>{records.fields["interviewer name"][0]}</strong>{" "}
                      on <strong>{records.fields["date"]}</strong>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      Student applied for{" "}
                      <strong>{records.fields["programme name"][0]}</strong>{" "}
                      under <strong>{records.fields["partner name"][0]}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Marketeer name:{" "}
                      <strong>{records.fields["marketeer name"][0]}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Comment: <strong>{records.fields["comments"]}</strong>
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      PQP Feedback:{" "}
                      <strong>{records.fields["pqp feedback"]}</strong>
                    </Typography>
                  </Box>
                </Paper>
                <br />
              </>
            );
          })
        ) : (
          <></>
        )}
        <br></br>
        {Student["english test done"] ? (
          <Paper>
            <Box p={4}>
              <Typography variant="h5" gutterBottom>
                English Test of {Student["first name"]} {Student["last name"]}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {Student["english test question"]}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {Student["english test answer"]}
              </Typography>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        <br />
        <br />
        <Paper>
          <Box p={4}>
            <Typography variant="h5" gutterBottom>
              Retake/Previous English Tests
            </Typography>
            {RetakeEnglishTest.length > 0 ? (
              RetakeEnglishTest.map((records, key) => (
                <>
                  <hr />
                  <Typography variant="h6" gutterBottom>
                    {records.fields["question"]}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {records.fields["answer"]}
                  </Typography>
                </>
              ))
            ) : (
              <></>
            )}
            {InterviewEnglishTest.length > 0 ? (
              InterviewEnglishTest.map((records, key) => (
                <>
                  <hr />
                  <Typography variant="h6" gutterBottom>
                    {records.fields["question"]}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {records.fields["answer"]}
                  </Typography>
                </>
              ))
            ) : (
              <></>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default FeedbackComponent;
