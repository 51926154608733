import { Box, Button, Container, Grid, TextField, Typography } from "@material-ui/core";
import md5 from "md5";
import React, { useState } from "react";
import AirtableBase from "../airtable";

import { useHistory } from "react-router-dom";

export default () => {
  const [User, setUser] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const [ErrorUser, setErrorUser] = useState({
    password: false,
    new_password: false,
    confirm_password: false,
  });

  const history = useHistory();

  const HandleValidation = async (field, form) => {
    let flag = 0;

    if (field === "password" || form) {
      if (User.password === "") {
        flag++;
        HandleSetState(setErrorUser, "password", true);
      } else {
        HandleSetState(setErrorUser, "password", false);
      }
    }

    if (field === "new_password" || form) {
      if (User.new_password === "") {
        flag++;
        HandleSetState(setErrorUser, "new_password", true);
      } else {
        HandleSetState(setErrorUser, "new_password", false);
      }
    }

    if (field === "confirm_password" || form) {
      if (User.confirm_password === "") {
        flag++;
        HandleSetState(setErrorUser, "confirm_password", true);
      } else {
        HandleSetState(setErrorUser, "confirm_password", false);
      }
    }

    return flag;
  };

  const HandleSubmit = async () => {
    const flag = await HandleValidation("", true);
    console.log(flag);
    if (flag === 0) {
      if (User.new_password !== User.confirm_password) {
        alert("The new password and confirm password do not match");
        HandleSetState(setErrorUser, "new_password", true);
        HandleSetState(setErrorUser, "confirm_password", true);
      } else {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (User.new_password.match(passwordRegex)) {
          AirtableBase("marketeers").find(localStorage.getItem("MarketeerId"), async (err, record) => {
            if (record) {
              if (record.fields.password === md5(User.password)) {
                AirtableBase("marketeers").update(
                  [
                    {
                      id: localStorage.getItem("MarketeerId"),
                      fields: {
                        password: md5(User.new_password),
                      },
                    },
                  ],
                  function (err, records) {
                    if (err) {
                      console.error(err);
                      return;
                    } else {
                      alert("Password successfully changed.");
                      history.push("/dashboard");
                    }
                  }
                );
              } else {
                HandleSetState(setErrorUser, "password", true);
                alert("The entered password is not correct");
              }
            }
          });
        } else {
          alert("Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter.");
          HandleSetState(setErrorUser, "new_password", true);
          HandleSetState(setErrorUser, "confirm_password", true);
        }
      }
    }
  };

  const HandleChange = (e) => {
    HandleSetState(setUser, e.target.name, e.target.value);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  return (
    <Box mx="0" my="100px">
      <Container maxWidth="lg">
        <Typography variant="h5">Change password</Typography>
        <br></br>
        <br></br>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <TextField
              name="password"
              type="password"
              color="secondary"
              label="Enter current password"
              value={User.password}
              fullWidth
              variant="outlined"
              error={ErrorUser.password}
              onChange={HandleChange}
              onBlur={HandleBlur}
              helperText="Please enter your current password"
            />
          </Grid>
          <Grid item xs={12} sm={9}></Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="new_password"
              type="password"
              color="secondary"
              label="Enter new password"
              value={User.new_password}
              fullWidth
              variant="outlined"
              error={ErrorUser.new_password}
              onChange={HandleChange}
              onBlur={HandleBlur}
              helperText="Please enter a new password"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="confirm_password"
              type="password"
              color="secondary"
              label="Confirm new password"
              value={User.confirm_password}
              fullWidth
              variant="outlined"
              error={ErrorUser.confirm_password}
              onChange={HandleChange}
              onBlur={HandleBlur}
              helperText="Please enter the new password again"
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Button variant="outlined" color="secondary" size="large" onClick={HandleSubmit}>
          Change Password
        </Button>
        <br />
        <br />
        <br />
        <Typography>
          Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter.
        </Typography>
      </Container>
    </Box>
  );
};
