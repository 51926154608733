import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AirtableBase from "../airtable";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Moment from "moment";
import __ from "lodash";

export default ({ setAlertOpen, setAlertType, setAlertMessage, setBackDropOpen, GetAppointments }) => {
  const { applicationid } = useParams();
  const [Student, setStudent] = useState({
    Code: "",
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    PhoneNumber: "",
    Agent: "",
    Marketeer: "",
    Campus: "",
    Partner: "",
    Course: "",
    Date: "",
    Interviewer: "",
    AgentEmailAddress: "",
  });
  const [Interviewer, setInterviewer] = useState([]);

  const [Time, setTime] = useState([]);
  const [TimeDisable, setTimeDisable] = useState(true);

  const [DateDisable, setDateDisable] = useState(true);
  const [DatePickerMaxDate, setDatePickerMaxDate] = useState(new Date());
  const [DatePickerMinDate, setDatePickerMinDate] = useState(new Date());

  const [InterviewerStorage] = useState(JSON.parse(localStorage.getItem("interviewers")));

  const [Appointment, setAppointment] = useState({
    Interviewer: "",
    Date: new Date(),
    Time: "",
  });

  const [AppointmentError, setAppointmentError] = useState({
    Interviewer: false,
    Date: false,
    Time: false,
  });

  useEffect(() => {
    AirtableBase("applications").find(applicationid, async (err, record) => {
      const student = record.fields;
      setStudent({
        Code: applicationid,
        FirstName: student.first_name,
        LastName: student.last_name,
        EmailAddress: student.email_address,
        PhoneNumber: student.phone_number,
        Agent: student.agent_name[0],
        Marketeer: student.marketing_officer[0],
        Campus: student.campus[0],
        Partner: student.partner[0],
        Course: student.course[0],
        Date: "",
        Interviewer: "",
        Time: "",
        AgentEmailAddress: student.agent_email[0],
      });
    });
  }, [applicationid]);

  useEffect(() => {
    AirtableBase("interviewer")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setInterviewer(records);
      });
  }, []);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const flag = await HandleValidation("", true);

    if (flag === 0) {
      await AirtableBase("students").create(
        [
          {
            fields: {
              code: Student.Code,
              "first name": Student.FirstName,
              "last name": Student.LastName,
              email: Student.EmailAddress,
              phone: Student.PhoneNumber,
              agent: Student.Agent,
              marketeer: [Student.Marketeer],
              campus: [Student.Campus],
              partner: [Student.Partner],
              course: [Student.Course],
              date: Appointment.Date,
              interviewer: [Appointment.Interviewer],
              documents: Student.Documents,
              time: [Appointment.Time],
              "agent email address": Student.AgentEmailAddress,
            },
          },
        ],
        (err, records) => {
          AirtableBase("applications").update(
            [
              {
                id: applicationid,
                fields: {
                  appointment: true,
                  appointments: [records[0].id],
                },
              },
            ],
            (err, records) => {
              if (err) {
                console.error(err);
                return;
              }
              if (records) {
                setAlertType("success");
                setAlertMessage("Appointment successfully added. Please Confirm the appointment under VIEW APPOINTMENTS");
                setAlertOpen(true);
                setBackDropOpen(false);
                GetAppointments();
                window.location.href = "/#/dashboard/appointments";
              }
            }
          );
        }
      );
    }
  };

  const HandleValidation = (field, form) => {
    let flag = 0;
    if (field === "Interviewer" || form) {
      if (Appointment.Interviewer === "") {
        flag++;
        HandleSetState(setAppointmentError, "Interviewer", true);
      } else {
        HandleSetState(setAppointmentError, "Interviewer", false);
      }
    }

    if (field === "Date" || form) {
      const today = new Date();
      const selection = Appointment.Date;
      if (selection === today) {
        flag++;
        HandleSetState(setAppointmentError, "Date", true);
      } else {
        HandleSetState(setAppointmentError, "Date", false);
      }
    }

    if (field === "Time" || form) {
      if (Appointment.Time === "") {
        flag++;
        HandleSetState(setAppointmentError, "Time", true);
      } else {
        HandleSetState(setAppointmentError, "Time", false);
      }
    }
    return flag;
  };

  const HandleDateChange = async (e) => {
    setBackDropOpen(true);
    HandleSetState(setAppointment, "Time", "");
    setTimeDisable(true);
    setTime([]);
    HandleSetState(setAppointment, "Date", e);
    const days = {
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    };
    const day = days[Moment(e).day()];

    const interviewer = __.find(InterviewerStorage, { id: Appointment.Interviewer });
    const idate = Moment(e).format("YYYY/MM/DD");
    await AirtableBase("students")
      .select({
        view: "Grid view",
        filterByFormula: `AND({interviewer email} = '${interviewer.email}', {date} = DATETIME_PARSE('${idate}','YYYY/MM/DD'))`,
      })
      .firstPage(async (err, records) => {
        const unaavailable = [];
        await records.forEach((record) => {
          unaavailable.push({ id: record.fields.time[0], time: record.fields["interview time"][0] });
        });
        await AirtableBase("slots")
          .select({
            view: "Grid view",
            filterByFormula: `AND({available}, FIND("${day}", days) > 0)`,
          })
          .firstPage(function (err, records) {
            let AllSlots = [];
            records.forEach(function (record) {
              AllSlots.push({ id: record.id, time: record.fields.time });
            });
            AllSlots = AllSlots.filter((o) => !unaavailable.find((o2) => o.id === o2.id));
            setTime(AllSlots);
            setTimeDisable(false);
            setBackDropOpen(false);
          });
      });
  };

  const HandleChange = (e) => {
    HandleSetState(setAppointment, e.target.name, e.target.value);
  };
  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleInterviewerChange = (e) => {
    if (e.target.value !== "") {
      setBackDropOpen(true);
      HandleChange(e);

      HandleSetState(setAppointment, "Date", new Date());
      HandleSetState(setAppointment, "Time", "");

      setDateDisable(true);
      setTimeDisable(true);

      setTime([]);

      AirtableBase("interviewer").find(e.target.value, function (err, record) {
        HandleSetState(setAppointment, "Date", new Date(record.fields.available_from));
        setDatePickerMinDate(record.fields.available_from);
        setDatePickerMaxDate(record.fields.available_to);
        setBackDropOpen(false);
        setDateDisable(false);
      });
    }
  };

  return (
    <Box mx="0" my="100px">
      <Container maxWidth="lg">
        <Typography variant="h5">
          Booking of {Student.FirstName} {Student.LastName}
        </Typography>
        <br></br>
        <br></br>
        <form onSubmit={HandleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth error={AppointmentError.Interviewer}>
                <InputLabel id="select-interviewer-label">Select the interviewer</InputLabel>
                <Select
                  labelId="select-interviewer-label"
                  name="Interviewer"
                  value={Appointment.Interviewer}
                  onChange={HandleInterviewerChange}
                  onBlur={HandleBlur}
                  label="Select the interviewer"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  {Interviewer.map((record, index) => {
                    if (record.fields.campus) {
                      if (record.fields.campus.includes(Student.Campus)) {
                        return (
                          <MenuItem value={record.id} key={index}>
                            {record.fields.name}
                          </MenuItem>
                        );
                      } else {
                        return <></>;
                      }
                    } else {
                      return <></>;
                    }
                  })}
                </Select>
                <FormHelperText>Please select the interviewer</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disablePast
                  value={Appointment.Date}
                  onChange={HandleDateChange}
                  onBlur={() => {
                    HandleValidation("Date", false);
                  }}
                  onClose={() => {
                    HandleValidation("Date", false);
                  }}
                  format="dd/MM/yyyy"
                  color="secondary"
                  fullWidth
                  helperText="Please select the date of interview"
                  inputVariant="outlined"
                  label="Date of interview"
                  maxDate={DatePickerMaxDate}
                  minDate={DatePickerMinDate}
                  disabled={DateDisable}
                  error={AppointmentError.Date}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth disabled={TimeDisable} error={AppointmentError.Time}>
                <InputLabel id="time-label">Select the time</InputLabel>
                <Select labelId="time-label" name="Time" value={Appointment.Time} label="time" onChange={HandleChange} onBlur={HandleBlur}>
                  <MenuItem value="">&nbsp;</MenuItem>
                  {Time.map((value, key) => {
                    return (
                      <MenuItem key={key} value={value.id}>
                        {value.time}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Please select the time of interview</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
