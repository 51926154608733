import React from "react";
import "./scss/app.css";
import { HashRouter, Route, Switch } from "react-router-dom";

import IndexComponent from "./components/index";
import DashboardComponent from "./components/dashboard";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" exact component={IndexComponent}></Route>
        <Route path="/dashboard" component={DashboardComponent}></Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
