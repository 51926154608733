import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Container, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Airtable from "airtable";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_KEY,
});

const AirtableBase = Airtable.base("appUs02XwDJaGh6w2");

const CapitalizeWords = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default () => {
  const [PQPGroup, setPQPGroup] = useState([]);
  const [PQPGroupID, setPQPGroupID] = useState("");

  useEffect(() => {
    const groups = [];
    AirtableBase("groups")
      .select()
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            record.fields.id = record.id;
            groups.push(record.fields);
          });
          fetchNextPage();
        },
        (err) => {
          if (err) console.error(err);
          setPQPGroup(groups);
        }
      );
  }, []);

  const [Data, setData] = useState([]);
  const [Coloumn, setColoumn] = useState([
    {
      key: "cell0",
      label: "Code",
    },
    {
      key: "cell1",
      label: "Group name",
    },
    {
      key: "cell01",
      label: "University ID",
    },
    {
      key: "cell2",
      label: "ROW",
    },
    {
      key: "cell3",
      label: "batch",
    },
    {
      key: "cell4",
      label: "First name",
    },
    {
      key: "cell5",
      label: "Last name",
    },
    {
      key: "cell6",
      label: "Email address",
    },
    {
      key: "cell7",
      label: "Phone number",
    },
    {
      key: "cell8",
      label: "Marketer",
    },
    {
      key: "cell9",
      label: "Agent",
    },
    {
      key: "cell10",
      label: "Course",
    },
    {
      key: "cell11",
      label: "Campus",
    },
    {
      key: "cell12",
      label: "Partner",
    },
    {
      key: "cell13",
      label: "Interviewer",
    },
    {
      key: "cell14",
      label: "Attendance Session 1",
    },
    {
      key: "cell15",
      label: "Attendance Session 2",
    },
    {
      key: "cell16",
      label: "Attendance Session 3",
    },
    {
      key: "cell17",
      label: "Attendance Session 4",
    },
    {
      key: "cell18",
      label: "Attendance Session 5",
    },
    {
      key: "cell19",
      label: "Attendance Session 6",
    },
    {
      key: "cell20",
      label: "Attendance Session 7",
    },
    {
      key: "cell21",
      label: "Attendance Session 8",
    },
    {
      key: "cell22",
      label: "Commitment",
    },
    {
      key: "cell23",
      label: "Maturity and Stability",
    },
    {
      key: "cell24",
      label: "Quality of spoken english",
    },
    {
      key: "cell25",
      label: "Minimum 50",
    },
    {
      key: "cell26",
      label: "Minimum 40",
    },
  ]);

  const downloadData = () => {
    const cols = Coloumn;
    let index = 27;
    const assessments = [];
    AirtableBase("assessments")
      .select({
        filterByFormula: `({pqp group}='${PQPGroupID}')`,
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            assessments.push(record.id);
            cols.push({
              key: "cell" + index,
              label: record.fields.assignment,
            });
            index++;
          });
          fetchNextPage();
        },
        (err) => {
          if (err) console.error(err);
          setColoumn(cols);
          setData([]);
          const students = [];
          AirtableBase("students")
            .select({
              filterByFormula: `({group name}='${PQPGroupID}')`,
              sort: [
                { field: "date", direction: "asc" },
                { field: "interview time", direction: "asc" },
              ],
            })
            .eachPage(
              (records, fetchNextPage) => {
                records.forEach(async (record) => {
                  const attendance = record.get("pqp attendance session") ? record.get("pqp attendance session") : "";
                  const solutions = [];
                  const stu = {
                    cell0: record.get("code"),
                    cell1: record.get("group name")[0],
                    cell01: record.get("university id") ? record.get("university id") : "",
                    cell2: record.get("number"),
                    cell3: record.get("batch"),
                    cell4: CapitalizeWords(record.get("first name")),
                    cell5: CapitalizeWords(record.get("last name")),
                    cell6: record.get("email"),
                    cell7: record.get("phone"),
                    cell8: record.get("marketeer name")[0],
                    cell9: record.get("agent"),
                    cell10: record.get("programme name")[0],
                    cell11: record.get("location name")[0],
                    cell12: record.get("partner name")[0],
                    cell13: record.get("interviewer name")[0],
                    cell14: attendance.includes("Session 1") ? "Present" : "",
                    cell15: attendance.includes("Session 2") ? "Present" : "",
                    cell16: attendance.includes("Session 3") ? "Present" : "",
                    cell17: attendance.includes("Session 4") ? "Present" : "",
                    cell18: attendance.includes("Session 5") ? "Present" : "",
                    cell19: attendance.includes("Session 6") ? "Present" : "",
                    cell20: attendance.includes("Session 7") ? "Present" : "",
                    cell21: attendance.includes("Session 8") ? "Present" : "",
                    cell22: record.get("Commitment"),
                    cell23: record.get("MaturityAndStability"),
                    cell24: record.get("QualityOfSpokenEnglish"),
                    cell25: "",
                    cell26: "",
                  };
                  await AirtableBase("solutions")
                    .select({
                      filterByFormula: `({student id}='${record.id}')`,
                    })
                    .eachPage(
                      (records, fetchNextPage) => {
                        records.forEach((record) => {
                          solutions[record.fields["assessment id"]] = record.fields.marks;
                        });
                        fetchNextPage();
                      },
                      function done(err) {
                        if (err) console.error(err);
                        let flag = 27;
                        assessments.forEach((element) => {
                          stu["cell" + flag] = solutions[element];
                          flag++;
                        });
                        students.push(stu);
                        setData([...students, stu]);
                      }
                    );
                });
                fetchNextPage();
              },
              (err) => {
                if (err) console.error(err);
              }
            );
        }
      );
  };

  return (
    <Box mx="20px" my="84px">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Download PQP Reports</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="pqp-group-label">Select the PQP Group</InputLabel>
              <Select
                labelId="pqp-group-label"
                name="Course"
                value={PQPGroupID}
                label="pqpgroup"
                onChange={(e) => {
                  setPQPGroupID(e.target.value);
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {PQPGroup.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.name}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select pqp group</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="outlined" color="secondary" size="large" onClick={downloadData}>
              Generate Report
            </Button>
          </Grid>
          {Data.length > 0 ? (
            <Grid item xs={12} sm={5}>
              <CSVLink data={Data} headers={Coloumn} filename={"report.csv"} target="_blank">
                <Button variant="outlined" color="secondary" size="large">
                  Download Report {Data.length}
                </Button>
              </CSVLink>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </Box>
  );
};
