import { Box, Button, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import AirtableBase from "../airtable";

export default ({ setAlertType, setAlertMessage, setAlertOpen, setBackDropOpen }) => {
  const history = useHistory();

  const { applicationid } = useParams();

  const [Student, setStudent] = useState({});

  const [Screening, setScreening] = useState({
    marital_status: "",
    children_under_18_years: "",
    how_many_children: "0",
    children_age: "",
    received_loan_before: "",
    loan_details: "",
    employment_status: "",
    working_status_years: "",
    immigration_status: "",
    how_long_in_the_uk: "",
    academic_qualification: "",
    professional_experience: "",
    documents_proof: "",
    availability_for_student: "",
    eligibility: "",
  });

  const [ErrorScreening, setErrorScreening] = useState({
    marital_status: false,
    children_under_18_years: false,
    how_many_children: false,
    children_age: false,
    received_loan_before: false,
    loan_details: false,
    employment_status: false,
    working_status_years: false,
    immigration_status: false,
    how_long_in_the_uk: false,
    academic_qualification: false,
    professional_experience: false,
    documents_proof: false,
    availability_for_student: false,
    eligibility: false,
  });

  const [MaritalStatus] = useState(["Married", "Single", "Divorced", "Widowed"]);
  const [EmploymentStatus] = useState(["Employed", "Un-employeed", "Self Employeed"]);
  const [ImmigrationStatus] = useState(["EU", "UK", "Refugee", "ILR", "EEU Family", "Leave to remain"]);
  const [AcademicQualification] = useState(["NA", "Level 1", "Level 2", "Level 3"]);
  const [ProfessionalExperience] = useState(["No Experience", "Less than 1 Year", "1-2 Years", "3-5 Years", "5+ Years", "Mature"]);
  const [DocumentsProof] = useState(["CV", "ID", "SOP", "Certificate", "Fee Assessment"]);
  const [StudentAvailability] = useState(["2 days", "Evenings"]);
  const [Eligibility] = useState(["Mark as complete", "Withdraw application", "Defer application", "Not approved for interview"]);

  useEffect(() => {
    AirtableBase("applications").find(applicationid, (error, record) => {
      if (error) console.error(error);
      const fields = record.fields;
      if ("marital_status" in fields) HandleSetState(setScreening, "marital_status", fields.marital_status);
      if ("children_under_18_years" in fields) HandleSetState(setScreening, "children_under_18_years", fields.children_under_18_years);
      if ("how_many_children" in fields) HandleSetState(setScreening, "how_many_children", fields.how_many_children);
      if ("children_age" in fields) HandleSetState(setScreening, "children_age", fields.children_age);
      if ("received_loan_before" in fields) HandleSetState(setScreening, "received_loan_before", fields.received_loan_before);
      if ("loan_details" in fields) HandleSetState(setScreening, "loan_details", fields.loan_details);
      if ("employment_status" in fields) HandleSetState(setScreening, "employment_status", fields.employment_status);
      if ("working_status_years" in fields) HandleSetState(setScreening, "working_status_years", fields.working_status_years);
      if ("immigration_status" in fields) HandleSetState(setScreening, "immigration_status", fields.immigration_status);
      if ("how_long_in_the_uk" in fields) HandleSetState(setScreening, "how_long_in_the_uk", fields.how_long_in_the_uk);
      if ("academic_qualification" in fields) HandleSetState(setScreening, "academic_qualification", fields.academic_qualification);
      if ("professional_experience" in fields) HandleSetState(setScreening, "professional_experience", fields.professional_experience);
      if ("documents_proof" in fields) HandleSetState(setScreening, "documents_proof", fields.documents_proof);
      if ("availability_for_student" in fields) HandleSetState(setScreening, "availability_for_student", fields.availability_for_student);
      if ("eligibility" in fields) HandleSetState(setScreening, "eligibility", fields.eligibility);

      setStudent(record);
    });
  }, [applicationid]);

  const HandleValidation = async (field, form) => {
    let flag = 0;

    if (field === "marital_status" || form) {
      if (Screening.marital_status === "") {
        flag++;
        HandleSetState(setErrorScreening, "marital_status", true);
      } else {
        HandleSetState(setErrorScreening, "marital_status", false);
      }
    }

    if (field === "children_under_18_years" || form) {
      if (Screening.children_under_18_years === "") {
        flag++;
        HandleSetState(setErrorScreening, "children_under_18_years", true);
      } else {
        HandleSetState(setErrorScreening, "children_under_18_years", false);
      }
    }

    if (field === "received_loan_before" || form) {
      if (Screening.received_loan_before === "") {
        flag++;
        HandleSetState(setErrorScreening, "received_loan_before", true);
      } else {
        HandleSetState(setErrorScreening, "received_loan_before", false);
      }
    }

    if (field === "employment_status" || form) {
      if (Screening.employment_status === "") {
        flag++;
        HandleSetState(setErrorScreening, "employment_status", true);
      } else {
        HandleSetState(setErrorScreening, "employment_status", false);
      }
    }

    if (field === "immigration_status" || form) {
      if (Screening.immigration_status === "") {
        flag++;
        HandleSetState(setErrorScreening, "immigration_status", true);
      } else {
        HandleSetState(setErrorScreening, "immigration_status", false);
      }
    }

    if (field === "how_long_in_the_uk" || form) {
      if (Screening.how_long_in_the_uk === "") {
        flag++;
        HandleSetState(setErrorScreening, "how_long_in_the_uk", true);
      } else {
        HandleSetState(setErrorScreening, "how_long_in_the_uk", false);
      }
    }

    if (field === "academic_qualification" || form) {
      if (Screening.academic_qualification === "") {
        flag++;
        HandleSetState(setErrorScreening, "academic_qualification", true);
      } else {
        HandleSetState(setErrorScreening, "academic_qualification", false);
      }
    }

    if (field === "professional_experience" || form) {
      if (Screening.professional_experience === "") {
        flag++;
        HandleSetState(setErrorScreening, "professional_experience", true);
      } else {
        HandleSetState(setErrorScreening, "professional_experience", false);
      }
    }

    if (field === "documents_proof" || form) {
      if (Screening.documents_proof === "") {
        flag++;
        HandleSetState(setErrorScreening, "documents_proof", true);
      } else {
        HandleSetState(setErrorScreening, "documents_proof", false);
      }
    }

    if (field === "availability_for_student" || form) {
      if (Screening.availability_for_student === "") {
        flag++;
        HandleSetState(setErrorScreening, "availability_for_student", true);
      } else {
        HandleSetState(setErrorScreening, "availability_for_student", false);
      }
    }

    if (field === "eligibility" || form) {
      if (Screening.eligibility === "") {
        flag++;
        HandleSetState(setErrorScreening, "eligibility", true);
      } else {
        HandleSetState(setErrorScreening, "eligibility", false);
      }
    }

    return flag;
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const flag = await HandleValidation("", true);

    if (flag === 0) {
      AirtableBase("applications").update(
        [
          {
            id: applicationid,
            fields: {
              marital_status: Screening.marital_status,
              children_under_18_years: Screening.children_under_18_years,
              how_many_children: Screening.how_many_children,
              children_age: Screening.children_age,
              received_loan_before: Screening.received_loan_before,
              loan_details: Screening.loan_details,
              employment_status: Screening.employment_status,
              working_status_years: Screening.working_status_years,
              immigration_status: Screening.immigration_status,
              how_long_in_the_uk: Screening.how_long_in_the_uk,
              academic_qualification: Screening.academic_qualification,
              professional_experience: Screening.professional_experience,
              documents_proof: Screening.documents_proof,
              availability_for_student: Screening.availability_for_student,
              eligibility: Screening.eligibility,
              screening: true,
            },
          },
        ],
        (err, records) => {
          if (err) {
            console.error(err);
            return;
          }
          if (records) {
            setAlertType("success");
            setAlertMessage("Application successfully updated");
            setAlertOpen(true);
            history.push("/dashboard/applications");
          }
          setBackDropOpen(false);
        }
      );
    }
  };

  const HandleChange = (e) => {
    HandleSetState(setScreening, e.target.name, e.target.value);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  return (
    <Box mx="0" my="100px">
      <Container maxWidth="lg" style={{ backgroundColor: "#ffffff", padding: "40px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h4" style={{ marginTop: "7px" }}>
              Screening for {Student.fields ? Student.fields.first_name + " " + Student.fields.last_name : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                window.history.back();
              }}
            >
              Go to applications
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.marital_status}>
              <InputLabel id="marital_status_label">Select the marital status</InputLabel>
              <Select
                labelId="marital_status_label"
                name="marital_status"
                value={Screening.marital_status}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Marital Status"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {MaritalStatus.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's marital status</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.children_under_18_years}>
              <InputLabel id="marital_status_label">Do you have children under 18 Years?</InputLabel>
              <Select
                labelId="children_under_18_years_label"
                name="children_under_18_years"
                value={Screening.children_under_18_years}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Children under 18 Years"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
              <FormHelperText>Please select student's marital status</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="how_many_children"
              color="secondary"
              label="How many children do you have?"
              variant="outlined"
              value={Screening.how_many_children}
              error={ErrorScreening.how_many_children}
              onBlur={HandleBlur}
              onChange={HandleChange}
              fullWidth
              helperText="Please enter the number of children"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="children_age"
              color="secondary"
              label="Age of children"
              variant="outlined"
              value={Screening.children_age}
              error={ErrorScreening.children_age}
              onBlur={HandleBlur}
              onChange={HandleChange}
              fullWidth
              helperText="Please enter the age of children"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.received_loan_before}>
              <InputLabel id="received_loan_before_label">Did you received a loan before</InputLabel>
              <Select
                labelId="received_loan_before_label"
                name="received_loan_before"
                value={Screening.received_loan_before}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Children under 18 Years"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
              <FormHelperText>Select if the student have received a loan before</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="loan_details"
              color="secondary"
              label="Loan details"
              variant="outlined"
              value={Screening.loan_details}
              error={ErrorScreening.loan_details}
              onBlur={HandleBlur}
              onChange={HandleChange}
              fullWidth
              helperText="Please enter the loan details"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.employment_status}>
              <InputLabel id="employment_status_label">Select the employment status</InputLabel>
              <Select
                labelId="employment_status_label"
                name="employment_status"
                value={Screening.employment_status}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Employment Status"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {EmploymentStatus.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's employment status</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="working_status_years"
              color="secondary"
              label="Working status years"
              variant="outlined"
              value={Screening.working_status_years}
              error={ErrorScreening.working_status_years}
              onBlur={HandleBlur}
              onChange={HandleChange}
              fullWidth
              helperText="Please enter the number working status years"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.immigration_status}>
              <InputLabel id="immigration_status_label">Select the immigration status</InputLabel>
              <Select
                labelId="immigration_status_label"
                name="immigration_status"
                value={Screening.immigration_status}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Immigration Status"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {ImmigrationStatus.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's immigration status</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="how_long_in_the_uk"
              color="secondary"
              label="How long in the UK?"
              variant="outlined"
              value={Screening.how_long_in_the_uk}
              error={ErrorScreening.how_long_in_the_uk}
              onBlur={HandleBlur}
              onChange={HandleChange}
              fullWidth
              helperText="Please enter the time spent in UK?"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.academic_qualification}>
              <InputLabel id="academic_qualification_label">Select the academic qualification</InputLabel>
              <Select
                labelId="academic_qualification_label"
                name="academic_qualification"
                value={Screening.academic_qualification}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Academic Qualification"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {AcademicQualification.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's academic qualification</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.professional_experience}>
              <InputLabel id="professional_experience_label">Select the professional experience</InputLabel>
              <Select
                labelId="professional_experience_label"
                name="professional_experience"
                value={Screening.professional_experience}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Professional experience"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {ProfessionalExperience.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's professional experience</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.documents_proof}>
              <InputLabel id="documents_proof_label">Select the documents proof</InputLabel>
              <Select
                labelId="documents_proof_label"
                name="documents_proof"
                value={Screening.documents_proof}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Documents proof"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {DocumentsProof.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's documents proof</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.availability_for_student}>
              <InputLabel id="availability_for_studentlabel">Select the student's availability for classes</InputLabel>
              <Select
                labelId="availability_for_student_label"
                name="availability_for_student"
                value={Screening.availability_for_student}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Student's availability for classes"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {StudentAvailability.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's availability for classes</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={10}></Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth error={ErrorScreening.eligibility}>
              <InputLabel id="eligibility_label">Please select an action for the application</InputLabel>
              <Select
                labelId="eligibility_label"
                name="eligibility"
                value={Screening.eligibility}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Eligibility"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Eligibility.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select an action for this application</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Button variant="outlined" color="secondary" size="large" onClick={HandleSubmit}>
          Submit Student
        </Button>
      </Container>
    </Box>
  );
};
