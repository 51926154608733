import {
  Box,
  Container,
  TextField,
  Typography,
  Grid,
  OutlinedInput,
  Button,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import AirtableBase from "../airtable";
import { useEffect } from "react";

import { Delete } from "@material-ui/icons";

//  https://0arwzgjox3.execute-api.eu-west-2.amazonaws.com/applications

export default ({ setBackDropOpen, setAlertType, setAlertMessage, setAlertOpen }) => {
  const { applicationid } = useParams();

  const [Documents, setDocuments] = useState([]);

  const [Student, setStudent] = useState({
    name: "",
    file: "",
  });

  const [ErrorStudent, setErrorStudent] = useState({
    name: false,
    file: false,
  });

  useEffect(() => {
    AirtableBase("documents")
      .select({ view: "Grid view", filterByFormula: `({application id}='${applicationid}')` })
      .firstPage((err, records) => {
        console.log(records);
        setDocuments(records);
      });
  }, [applicationid]);

  const HandleValidation = async (field, form) => {
    let flag = 0;

    if (field === "name" || form) {
      if (Student.name === "") {
        flag++;
        HandleSetState(setErrorStudent, "name", true);
      } else {
        HandleSetState(setErrorStudent, "name", false);
      }
    }

    if (field === "file" || form) {
      if (Student.file === "") {
        flag++;
        HandleSetState(setErrorStudent, "file", true);
      } else {
        HandleSetState(setErrorStudent, "file", false);
      }
    }

    return flag;
  };

  const HandleUpload = async () => {
    const formData = new FormData();
    formData.append("file", Student.file);
    return await axios.post("https://0arwzgjox3.execute-api.eu-west-2.amazonaws.com/applications/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const HandleDelete = async (id) => {
    if (window.confirm("Do you really want to delete?")) {
      AirtableBase("documents").destroy([id], function (err, deletedRecords) {
        if (err) {
          console.error(err);
          return;
        }
        if (deletedRecords) window.location.reload();
      });
    }
  };

  const HandleSubmit = async (e) => {
    setBackDropOpen(true);
    e.preventDefault();
    const flag = await HandleValidation("", true);
    if (flag === 0) {
      const upload = await HandleUpload();
      AirtableBase("documents").create(
        [
          {
            fields: {
              name: Student.name,
              application: [applicationid],
              "application id": applicationid,
              document: upload.data.data.Location,
            },
          },
        ],
        { typecast: true },
        function (err, records) {
          if (err) {
            console.error(err);
            return;
          }
          if (records) {
            setDocuments((doc) => [...doc, records[0]]);
            setAlertType("success");
            setAlertMessage("Documeny successfully uploaded");
            setAlertOpen(true);
            setStudent({
              name: "",
              file: "",
            });
            document.getElementById("file").value = null;
          }
          setBackDropOpen(false);
        }
      );
      setBackDropOpen(false);
    } else {
      setBackDropOpen(false);
    }
  };

  const HandleFile = (e) => {
    HandleSetState(setStudent, e.target.name, e.target.files[0]);
  };

  const HandleChange = (e) => {
    HandleSetState(setStudent, e.target.name, e.target.value);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  return (
    <Box mx="0" my="100px">
      <form>
        <Container maxWidth="lg" style={{ backgroundColor: "#ffffff", padding: "40px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <Typography variant="h4" style={{ marginTop: "7px" }}>
                Uploaded Documents
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => {
                  window.history.back();
                }}
              >
                Go to applications
              </Button>
            </Grid>
          </Grid>
          <br />
          <br />
          {Document.length === 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Document</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Documents.map((row, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell component="th">{++key}</TableCell>
                        <TableCell component="th">{row.fields.name}</TableCell>
                        <TableCell component="th" scope="row">
                          <a href={row.fields.document} rel="noopener noreferrer" target="_blank">
                            Download Document
                          </a>
                        </TableCell>
                        <TableCell>
                          <IconButton aria-label="delete" onClick={() => HandleDelete(row.id)}>
                            <Delete fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h6" style={{ marginTop: "7px" }}>
              No Documents Uploaded
            </Typography>
          )}
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h4" style={{ marginTop: "7px" }}>
            Upload Supporting Documents
          </Typography>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <TextField
                name="name"
                color="secondary"
                label="Document name"
                variant="outlined"
                value={Student.name}
                error={ErrorStudent.name}
                onBlur={HandleBlur}
                onChange={HandleChange}
                fullWidth
                helperText="Please enter the name of the document"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <OutlinedInput
                type="file"
                id="file"
                name="file"
                color="secondary"
                label="Upload Document"
                error={ErrorStudent.file}
                onChange={HandleFile}
                fullWidth
              ></OutlinedInput>
            </Grid>
          </Grid>
          <br />
          <br />
          <Button variant="outlined" color="secondary" size="large" onClick={HandleSubmit}>
            Upload Document
          </Button>
        </Container>
      </form>
    </Box>
  );
};
