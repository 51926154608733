import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Validator from "validator";

import { useHistory } from "react-router-dom";

import md5 from "md5";

import lsclondon from "../assets/lsclondon.svg";
import AirtableBase from "../airtable";

const IndexComponent = () => {
  const [EmailAddress, setEmailAddress] = useState("");
  const [Password, setPassword] = useState("");
  const [EmailAddressError, setEmailAddressError] = useState(false);
  const [PasswordError, setPasswordError] = useState(false);
  const [EmailAddressMessage, setEmailAddressMessage] = useState(
    "Please enter your full email address"
  );
  const [PasswordMessage, setPasswordMessage] = useState(
    "Please enter your password"
  );
  const [BackDropOpen, setBackDropOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const InsertLocal = async () => {
    setBackDropOpen(true);
    localStorage.setItem("version", 7);
    await AirtableBase("campus")
      .select({
        view: "Grid view",
      })
      .firstPage(function (err, records) {
        const campuses = [];
        records.forEach(function (record) {
          campuses.push({ id: record.id, name: record.get("name") });
        });
        localStorage.setItem("campuses", JSON.stringify(campuses));
      });

    await AirtableBase("partners")
      .select({
        view: "Grid view",
      })
      .firstPage(function (err, records) {
        const partners = [];
        records.forEach(function (record) {
          partners.push({ id: record.id, name: record.get("name") });
        });
        localStorage.setItem("partners", JSON.stringify(partners));
      });

    await AirtableBase("courses")
      .select({
        view: "Grid view",
      })
      .firstPage(function (err, records) {
        const courses = [];
        records.forEach(function (record) {
          courses.push({ id: record.id, name: record.get("name") });
        });
        localStorage.setItem("courses", JSON.stringify(courses));
      });

    await AirtableBase("interviewer")
      .select({
        view: "Grid view",
      })
      .firstPage(function (err, records) {
        const interviewer = [];
        records.forEach(function (record) {
          interviewer.push({
            id: record.id,
            name: record.get("name"),
            email: record.get("email"),
            zoom: record.get("zoom link"),
            english: record.get("english test link"),
          });
        });
        localStorage.setItem("interviewers", JSON.stringify(interviewer));
      });

    const marketeers = [];
    AirtableBase("marketeers")
      .select({
        view: "Grid view",
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            if (record.fields.type.includes("Marketeer"))
              marketeers.push({
                id: record.id,
                name: record.get("name"),
                email: record.get("email"),
              });
          });
          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          localStorage.setItem("marketeers", JSON.stringify(marketeers));
        }
      );

    const slots = [];
    AirtableBase("slots")
      .select({
        view: "Grid view",
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            slots.push({
              id: record.id,
              time: record.get("time"),
              available: record.get("available"),
              days: record.get("days"),
            });
          });
          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          localStorage.setItem("slots", JSON.stringify(slots));
          setBackDropOpen(false);
        }
      );
  };

  const validator = () => {
    let flag = 0;
    if (EmailAddress === "") {
      flag++;
      setEmailAddressError(true);
      setEmailAddressMessage("Please enter your full email address");
    } else if (!Validator.isEmail(EmailAddress)) {
      flag++;
      setEmailAddressError(true);
      setEmailAddressMessage("Entered email address is not valid");
    }
    if (Password === "") {
      flag++;
      setPasswordError(true);
      setPasswordMessage("Please enter your password");
    }
    return flag;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    setBackDropOpen(!BackDropOpen);
    const flag = validator();
    if (flag === 0) {
      setEmailAddressError(false);
      setEmailAddress(Validator.normalizeEmail(Validator.trim(EmailAddress)));
      AirtableBase("marketeers")
        .select({
          view: "Grid view",
          filterByFormula: "({email} = '" + EmailAddress + "')",
        })
        .firstPage((error, record) => {
          if (record.length === 0) {
            setBackDropOpen(false);
            setEmailAddressError(true);
            setEmailAddressMessage(
              "Entered email address does not exist in the system"
            );
          } else {
            localStorage.setItem("MarketeerId", record[0].id);
            localStorage.setItem("MarketeerEmail", EmailAddress);
            AirtableBase("marketeers")
              .select({
                filterByFormula:
                  "AND({email} = '" +
                  EmailAddress +
                  "', {password}='" +
                  md5(Password) +
                  "', {enabled}=1)",
              })
              .firstPage(async function (err, records) {
                if (err) {
                  console.error(err);
                  return;
                }
                if (records.length === 0) {
                  setEmailAddressError(true);
                  setEmailAddressMessage("Entered email address is not valid");
                  setPasswordError(true);
                  setPasswordMessage("Entered password is not valid");
                  setBackDropOpen(false);
                } else {
                  await InsertLocal();
                  localStorage.setItem(
                    "access",
                    JSON.stringify(records[0].fields.type)
                  );
                  history.push("/dashboard");
                }
              });
          }
        });
    } else {
      setBackDropOpen(BackDropOpen);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
        className="IndexContainer"
      >
        <Grid item xs={4}>
          <img
            src={lsclondon}
            alt="Logo of London School of Commerce"
            className="logo"
          ></img>
          <Typography variant="h6" align="center">
            Welcome to the
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            Interview Appointment System
          </Typography>
          <form onSubmit={HandleSubmit} autoComplete="off">
            <Box my={3}>
              <TextField
                id="outlined-required"
                color="secondary"
                label="Email Address"
                value={EmailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                fullWidth
                helperText={EmailAddressMessage}
                variant="outlined"
                error={EmailAddressError}
              />
            </Box>
            <Box my={3}>
              <TextField
                type="password"
                id="outlined-required"
                color="secondary"
                label="Password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                helperText={PasswordMessage}
                variant="outlined"
                error={PasswordError}
              />
            </Box>
            <center>
              <Button
                type="submit"
                size="medium"
                variant="contained"
                color="secondary"
                fullWidth
                disableElevation
              >
                Login
              </Button>
            </center>
          </form>
        </Grid>
      </Grid>
      <Backdrop open={BackDropOpen} style={{ color: "#fff", zIndex: 10 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default IndexComponent;
