import React, { useState } from "react";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box, Button, Typography } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import __ from "lodash";
import AirtableBase from "../airtable";
import { Send, BorderColor, DoneAll } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const ViewInterviewsComponent = ({
  Interviewer,
  Interviews,
  GetInterviews,
}) => {
  const { type } = useParams();

  useEffect(() => {
    GetInterviews(Interviewer.email, type);
  }, [type]);

  const [Campuses] = useState(JSON.parse(localStorage.getItem("campuses")));
  const [Partners] = useState(JSON.parse(localStorage.getItem("partners")));
  const [Courses] = useState(JSON.parse(localStorage.getItem("courses")));
  const [Marketeers] = useState(JSON.parse(localStorage.getItem("marketeers")));
  const [Slots] = useState(JSON.parse(localStorage.getItem("slots")));

  const NormalizePhone = (phone) => {
    phone = phone.replace(/[^0-9]/g, "");
    const length = phone.length;
    if (length === 10) {
      return "+44" + phone;
    }
    if (length === 11) {
      return "+44" + phone.slice(-10);
    }
    return phone;
  };

  const SendEnglishTest = async (data) => {
    const html = `Dear ${data.name}, <br><br>Thank you for taking part in the 'Zoom' interview earlier today. As discussed in your interview, you will need to <strong>complete a written English language test</strong>, as part of our Admissions process.<br><br>The test is of <strong>20 minutes’</strong> duration. Please click on the link below and this will direct you to your test. Please note that, the link will expire within <strong>1 hour</strong> of it being sent to you.<br><br>English language test link:  <a href="${data.link}">${data.link}</a><br><br>We wish you every success.<br><br>Kind regards,<br>${data.interviewer}<br>LSC Admissions`;
    const sent = await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
      {
        message: {
          to: data.email,
          name: "LSC Interviews",
          from: "interviews@lsclondon.co.uk",
          subject: "English Test with London School of Commerce",
          body: html,
        },
      }
    );

    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/sms",
      {
        messages: [
          {
            source_address: "LSC",
            destination_address: NormalizePhone(data.phone),
            content: `Thank you for taking part in the 'Zoom' interview earlier today. As discussed in your interview, you will need to complete a written English language test, as part of our Admissions process. The test is of 20 minutes’ duration. English language test link:  ${data.link}`,
          },
        ],
      }
    );

    if (sent.data.success) {
      AirtableBase("students").update(
        [
          {
            id: data.id,
            fields: {
              "english test": true,
              "english test retake": 1,
            },
          },
        ],
        (error, record) => {
          GetInterviews(Interviewer.email, type);
        }
      );
    }
  };

  const SendRejectionEmail = async (data) => {
    const html = `<p>Dear ${data.name},<br /><br />Thank you for your recent application to study with us at London School of Commerce.<br /><br />Having considered your application and the outcome of your recent Interview, we are unable to offer you a place on this particular occasion.<br /><br />However, should you wish to consider further opportunities offered by LSC throughout the year, please contact your Marketing Officer for support and information.<br /><br />We wish you every success for your future plans.<br /><br />Best Wishes.<br /><strong>LSC Admissions Team</strong></p>`;
    const sent = await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
      {
        message: {
          to: data.email,
          name: "LSC Interviews",
          from: "interviews@lsclondon.co.uk",
          subject: "Your Application for Admission",
          body: html,
        },
      }
    );

    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/sms",
      {
        messages: [
          {
            source_address: "LSC",
            destination_address: NormalizePhone(data.phone),
            content: `Thank you for your recent application to study with us at London School of Commerce.\n\nHaving considered your application and the outcome of your recent Interview, we are unable to offer you a place on this particular occasion.\n\nHowever, should you wish to consider further opportunities offered by LSC throughout the year, please contact your Marketing Officer for support and information.\n\nWe wish you every success for your future plans.\n\nBest Wishes.\nLSC Admissions Team`,
          },
        ],
      }
    );

    if (sent.data.success) {
      AirtableBase("students").update(
        [
          {
            id: data.id,
            fields: {
              rejection_email: true,
            },
          },
        ],
        (error, record) => {
          GetInterviews(Interviewer.email, type);
        }
      );
    }
  };

  const SendPQPTest = async (data) => {
    const assessments = ["4", "5", "6", "7", "8", "13", "14", "15"];
    let assessment_id =
      assessments[Math.floor(Math.random() * assessments.length)];
    const uuid_id = uuidv4();
    const html = `Dear ${data.name},<br /><br />We are delighted to confirm that you have been successful in the interview for Admission with the London School of Commerce, and are now invited to attend and complete the Pre-Qualifying Programme Assessment.<br /><br />Information regarding the Pre-Qualifying Programme Assessment:</p><ul>
    <li>There are 3 questions to answer for the Pre-Qualifying Programme Assessment and completion of the academic assessments is compulsory.</li>
    <li>You must individually save the answers to each question and upon completion of all 3 questions, you must submit.</li>
    <li>You have 2 hours and 30 minutes to complete the Pre-Qualifying Programme Assessment.</li>
    <li>The link below will expire within 24-hours of it having been sent to you.</li>
    </ul><p>IMPORTANT: The PQP Assessment can only be accessed once, once you click on the link you must complete the entire assessment. You will be unable to pause or restart the assessment.<br /><br />Please click on the link below to access the PQP Assessment:<br /><br />Link: <a href="https://www.assessments.lsclondon.co.uk/start/?first_name=${data.first_name}&last_name=${data.last_name}&email_address=${data.email}&reference_id=${uuid_id}&assessment_id=${assessment_id}">Click here for the test</a><br /><br />Should you have any further queries, kindly contact our team via email at <a href="mailto:pqp@lsclondon.co.uk">pqp@lsclondon.co.uk</a><br /><br />Best wishes,<br />${data.interviewer}<br>Admissions Department`;

    const sent = await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
      {
        message: {
          to: data.email,
          name: "LSC Interviews",
          from: "pqp@lsclondon.co.uk",
          subject: "Invitation for your Pre-Qualifying Programme Assessment",
          body: html,
        },
      }
    );

    if (sent.data.success) {
      AirtableBase("students").update(
        [
          {
            id: data.id,
            fields: {
              "pqp test": true,
              "pqp test code": `https://www.assessments.lsclondon.co.uk/administrator/pqp/?student=${uuid_id}`,
              assessment_reference: uuid_id,
              pqp_test_retake: data.pqp_test_retake
                ? data.pqp_test_retake + 1
                : 1,
            },
          },
        ],
        (error, record) => {
          GetInterviews(Interviewer.email, type);
        }
      );
    }
  };

  const RetakeEnglishTest = async (data) => {
    const html = `Dear ${data.name}, <br><br>As discussed, you will need to <strong>Retake the written English language test</strong>, as part of our Admissions process.<br><br>The test is of <strong>20 minutes’</strong> duration. Please click on the link below and this will direct you to your test. Please note that, the link will expire within <strong>1 hour</strong> of it being sent to you.<br><br>Retake English language test link:  <a href="https://english.lsclondon.co.uk/?retake">https://english.lsclondon.co.uk/?retake</a><br><br>We wish you every success.<br><br>Kind regards,<br>${data.interviewer}<br>LSC Admissions`;
    const sent = await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
      {
        message: {
          to: data.email,
          name: "LSC Interviews",
          from: "interviews@lsclondon.co.uk",
          subject: "Retake English Test with London School of Commerce",
          body: html,
        },
      }
    );

    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/sms",
      {
        messages: [
          {
            source_address: "LSC",
            destination_address: NormalizePhone(data.phone),
            content: `Thank you for taking part in the 'Zoom' interview earlier today. As discussed in your interview, you will need to complete a written English language test, as part of our Admissions process. The test is of 20 minutes’ duration. English language test link: https://english.lsclondon.co.uk/?retake`,
          },
        ],
      }
    );

    if (sent.data.success) {
      AirtableBase("students").update(
        [
          {
            id: data.id,
            fields: {
              "english test": true,
              "english test retake": data.retake + 1,
            },
          },
        ],
        (error, record) => {
          GetInterviews(Interviewer.email, type);
        }
      );
    }
  };

  return (
    <Box mx="20px" my="84px">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {Interviews.map((data, key) => {
              const campus = __.find(Campuses, { id: data.campus });
              const partner = __.find(Partners, { id: data.partner });
              const course = __.find(Courses, { id: data.course });
              const marketeer = __.find(Marketeers, { id: data.marketeer });
              const slots = __.find(Slots, { id: data.slot });
              return (
                <React.Fragment key={key}>
                  {data["Old Rebook Ref"] ? (
                    <TableRow style={{ backgroundColor: "#f50057" }}>
                      <TableCell colSpan={7} style={{ padding: 2 }}>
                        <Typography variant="button" style={{ color: "#fff" }}>
                          &nbsp;&nbsp;&nbsp;Student is Rebooked
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                  <TableRow border={0}>
                    <TableCell rowSpan={2} style={{ padding: 2 }}></TableCell>
                    <TableCell>
                      <strong>Full name: </strong>
                      {data.name}
                    </TableCell>
                    <TableCell>
                      <strong>Email: </strong>
                      {data.email}
                    </TableCell>
                    <TableCell>
                      <strong>Marketeer: </strong>
                      {marketeer.name}
                    </TableCell>
                    <TableCell>
                      <strong>Date: </strong>
                      {data.date}
                    </TableCell>
                    <TableCell>
                      <strong>Course: </strong>
                      {course.name}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "rgb(224 224 224)" }}>
                      {data["Rejected by TA"] ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={() => {
                            SendRejectionEmail({
                              first_name: data.first_name,
                              last_name: data.last_name,
                              name: data.name,
                              phone: data.phone,
                              email: data.email,
                              interviewer: Interviewer.name,
                              id: data.id,
                            });
                          }}
                          startIcon={<Send />}
                        >
                          {data.rejection_email ? "Re-" : ""}Send Reject
                        </Button>
                      ) : (
                        <>
                          {partner.name === "University of Bedfordshire" ? (
                            <>
                              {data["english test done"] ? (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  disabled
                                  startIcon={<DoneAll />}
                                >
                                  Test done
                                </Button>
                              ) : (
                                <>
                                  {data.english_test ? (
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      disableElevation
                                      onClick={() => {
                                        RetakeEnglishTest({
                                          name: data.name,
                                          phone: data.phone,
                                          email: data.email,
                                          link: Interviewer[
                                            "english test link"
                                          ],
                                          interviewer: Interviewer.name,
                                          id: data.id,
                                          retake: data.english_test_retake,
                                        });
                                      }}
                                      startIcon={<Send />}
                                    >
                                      Re-English
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="secondary"
                                      onClick={() => {
                                        SendEnglishTest({
                                          first_name: data["first name"],
                                          last_name: data["last name"],
                                          name: data.name,
                                          phone: data.phone,
                                          email: data.email,
                                          link: Interviewer[
                                            "english test link"
                                          ],
                                          interviewer: Interviewer.name,
                                          id: data.id,
                                        });
                                      }}
                                      startIcon={<Send />}
                                    >
                                      English
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {data["Approved by TA"] ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                  onClick={() => {
                                    SendPQPTest({
                                      first_name: data.first_name,
                                      last_name: data.last_name,
                                      name: data.name,
                                      phone: data.phone,
                                      email: data.email,
                                      interviewer: Interviewer.name,
                                      id: data.id,
                                      pqp_test_retake: data.pqp_test_retake,
                                    });
                                  }}
                                  startIcon={<Send />}
                                >
                                  {data.pqp_test
                                    ? `${
                                        data.pqp_test_retake
                                          ? data.pqp_test_retake
                                          : ""
                                      } Re-`
                                    : ""}
                                  PQP
                                </Button>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Code: </strong>
                      {data.code}
                    </TableCell>
                    <TableCell>
                      <strong>Phone: </strong>
                      {data.phone}
                    </TableCell>
                    <TableCell>
                      <strong>Agent: </strong>
                      {data.agent}
                      {data.agentEmail ? " <" + data.agentEmail + ">" : ""}
                    </TableCell>
                    <TableCell>
                      <strong>Time: </strong>
                      {slots ? slots.time : ""}
                    </TableCell>
                    <TableCell>
                      <strong>Campus: </strong>
                      {partner.name}, {campus.name}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "rgb(224 224 224)" }}>
                      <Button
                        variant="contained"
                        size="small"
                        color={data.interviewed ? "secondary" : "primary"}
                        onClick={() => {
                          window.location.href =
                            "/#/dashboard/feedback/" + data.id;
                        }}
                        startIcon={<BorderColor />}
                      >
                        Feedback
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow border={0}>
                    <TableCell
                      colSpan={5}
                      style={{ backgroundColor: "#ededed" }}
                    >
                      {data.interviewed ? (
                        <>
                          <strong style={{ color: "#f50057" }}>
                            {data.feedback}:{" "}
                          </strong>
                          {data.comments}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell style={{ width: "350px" }}>
                      {data["Approved by TA"] ? (
                        <Button
                          style={{ color: "#ffffff", backgroundColor: "green" }}
                          variant="contained"
                          size="small"
                        >
                          Approved by Management
                        </Button>
                      ) : (
                        ""
                      )}
                      {data["Rejected by TA"] ? (
                        <Button
                          style={{ color: "#ffffff", backgroundColor: "black" }}
                          variant="contained"
                          size="small"
                        >
                          Rejected by Management
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "rgb(224 224 224)" }}>
                      {data.documents ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          href={data.documents}
                          target="_blank"
                          startIcon={<CloudDownloadIcon />}
                        >
                          ID Proof
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: "#ccc" }}>
                    <TableCell colSpan={7} style={{ padding: 4 }}></TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ViewInterviewsComponent;
