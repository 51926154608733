import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Edit, CloudUpload, DoneAll } from "@material-ui/icons";

import AirtableBase from "../airtable";

export default ({ setAlertType, setAlertMessage, setAlertOpen, setBackDropOpen }) => {
  const [Applications, setApplications] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const rec = [];
    AirtableBase("applications")
      .select({
        view: "Grid view",
        filterByFormula: "({eligibility} = 'Mark as complete')",
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            rec.push(record);
          });

          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          setApplications(rec);
        }
      );
  }, []);

  const status = (fields) => {
    let string = "";

    if (fields.confirm) {
      string += "Submitted";
    }

    if (fields.confirm && fields.screening) {
      string += ` > Screened by Marketeer (Result: ${fields.eligibility})`;
    }

    if (fields.confirm && fields.screening && fields.appointment) {
      string += " > Interview Appointment taken";
    }

    return string;
  };

  /*
  const confirmation = (applicationid) => {
    const confirmation = window.confirm("Are you sure you want to submit this student application?");
    if (confirmation) {
      setBackDropOpen(true);
      AirtableBase("applications").update(
        [
          {
            id: applicationid,
            fields: {
              confirm: true,
            },
          },
        ],
        (err, records) => {
          if (err) {
            console.error(err);
            return;
          }
          if (records) {
            setAlertType("success");
            setAlertMessage("Application successfully confirmed");
            setAlertOpen(true);
          }
          setBackDropOpen(false);
        }
      );
    }
  };
  */

  return (
    <Box mx="20px" my="84px">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Full name</TableCell>
              <TableCell align="left">University</TableCell>
              <TableCell align="left">Course</TableCell>
              <TableCell align="left">Contact</TableCell>
              <TableCell align="left">Agent</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Applications.map((row, key) => {
              return (
                <>
                  <TableRow key={key}>
                    <TableCell component="th">{row.id}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.fields.title} {row.fields.first_name} {row.fields.last_name}
                    </TableCell>
                    <TableCell>{row.fields.partner_name}</TableCell>
                    <TableCell>{row.fields.course_name}</TableCell>
                    <TableCell>
                      <strong>Email:</strong> {row.fields.email_address}
                    </TableCell>

                    <TableCell>
                      <strong>Agent:</strong> {row.fields.agent_name}
                    </TableCell>
                    <TableCell rowSpan={2} style={{ backgroundColor: "rgb(224 224 224)" }}>
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        style={{ margin: "5px" }}
                        startIcon={<Edit />}
                        onClick={() => {
                          history.push("/dashboard/registration/" + row.id);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color={"documents" in row.fields ? (row.fields.documents.length > 0 ? "secondary" : "primary") : "primary"}
                        size="small"
                        style={{ margin: "5px" }}
                        startIcon={<CloudUpload />}
                        onClick={() => {
                          history.push("/dashboard/upload-documents/" + row.id);
                        }}
                      >
                        Upload
                      </Button>
                      <br></br>
                      <Button
                        variant="contained"
                        color={row.fields.screening ? "secondary" : "primary"}
                        style={{ margin: "5px" }}
                        size="small"
                        startIcon={<DoneAll />}
                        onClick={() => history.push("/dashboard/screening/" + row.id)}
                      >
                        Screening
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <strong>Status:</strong> {status(row.fields)}
                    </TableCell>
                    <TableCell>
                      <strong>Phone:</strong> {row.fields.phone_number}
                    </TableCell>
                    <TableCell>
                      <strong>Marketeer:</strong> {row.fields.marketing_officer_name}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: "#ccc" }}>
                    <TableCell height={1} colSpan="7" style={{ padding: 4 }}></TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
