import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Validator from "validator";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { useHistory, useParams } from "react-router-dom";
import AirtableBase from "../airtable";

export default ({
  setAlertOpen,
  setAlertType,
  setAlertMessage,
  setBackDropOpen,
}) => {
  const [Title] = useState(["Mr", "Ms"]);
  const [Gender] = useState(["Male", "Female", "Other"]);

  const [Partner] = useState([
    { id: "recV05qS7C7mZFWiL", name: "Bath Spa University" },
    { id: "recbz9XOIyzCOGWb8", name: "Canterbury Christ Church University" },
  ]);

  const { applicationid } = useParams();

  const history = useHistory();
  const [Country, setCountry] = useState([]);
  const [Nationality, setNationality] = useState([]);
  const [Ethnicity, setEthnicity] = useState([]);
  const [Disability, setDisability] = useState([]);
  const [ResidentialCategory, setResidentialCategory] = useState([]);
  const [Qualification, setQualification] = useState([]);
  const [Marketeers, setMarketeers] = useState([]);
  const [FeePayer, setFeePayer] = useState([]);
  const [UKRegion, setUKRegion] = useState([]);
  const [Student, setStudent] = useState({
    partner: "",
    campus: "",
    course: "",
    title: "",
    first_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    country_of_birth: "",
    nationality: "",
    ethnic_origin: "",
    email_address: "",
    phone_number: "",
    marketing_officer: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    address_line_4: "",
    city: "",
    postcode: "",
    area_of_residence: "",
    residential_category: "",
    uk_entry_date: "",
    personal_statement: "",
    school_college: "",
    highest_qualification: "",
    country_of_study: "",
    disability: "",
    fee_payer: "",
    declaration: true,
    agent: "",
    signature: "",
  });

  const [ErrorStudent, setErrorStudent] = useState({
    title: false,
    first_name: false,
    last_name: false,
    gender: false,
    date_of_birth: false,
    country_of_birth: false,
    nationality: false,
    ethnic_origin: false,
    email_address: false,
    phone_number: false,
    marketing_officer: false,
    address_line_1: false,
    address_line_2: false,
    address_line_3: false,
    address_line_4: false,
    city: false,
    postcode: false,
    area_of_residence: false,
    residential_category: false,
    uk_entry_date: false,
    personal_statement: false,
    school_college: false,
    highest_qualification: false,
    country_of_study: false,
    disability: false,
    fee_payer: false,
    signature: false,
    partner: false,
  });

  useEffect(() => {
    if (applicationid) {
      AirtableBase("applications").find(applicationid, (err, record) => {
        if (err) {
          console.error(err);
          return;
        }
        setStudent({
          title: record.get("title"),
          first_name: record.get("first_name"),
          last_name: record.get("last_name"),
          gender: record.get("gender"),
          date_of_birth: record.get("date_of_birth"),
          country_of_birth: record.get("country_of_birth")[0],
          nationality: record.get("nationality")[0],
          ethnic_origin: record.get("ethnic_origin")[0],
          email_address: record.get("email_address"),
          phone_number: record.get("phone_number"),
          marketing_officer: record.get("marketing_officer")[0],
          address_line_1: record.get("address_line_1"),
          address_line_2: record.get("address_line_2"),
          address_line_3: record.get("address_line_3"),
          address_line_4: record.get("address_line_4"),
          city: record.get("city"),
          postcode: record.get("postcode"),
          area_of_residence: record.get("area_of_residence")[0],
          residential_category: record.get("residential_category")[0],
          uk_entry_date: record.get("uk_entry_date"),
          personal_statement: record.get("personal_statement"),
          school_college: record.get("school_college"),
          highest_qualification: record.get("highest_qualification")[0],
          country_of_study: record.get("country_of_study")[0],
          disability: record.get("disability")[0],
          fee_payer: record.get("fee_payer")[0],
          declaration: true,
          agent: record.get("agent"),
          signature: record.get("signature"),
        });
      });
    }
  }, [applicationid]);

  useEffect(() => {
    const rec = [];
    AirtableBase("country_system")
      .select({ view: "Grid view" })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            rec.push(record);
          });

          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          setCountry(rec);
        }
      );
  }, []);

  useEffect(() => {
    AirtableBase("ethnicity_system")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setEthnicity(records);
      });
  }, []);

  useEffect(() => {
    AirtableBase("disability_system")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setDisability(records);
      });
  }, []);

  useEffect(() => {
    const rec = [];
    AirtableBase("nationality_system")
      .select({ view: "Grid view" })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            rec.push(record);
          });

          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          setNationality(rec);
        }
      );
  }, []);

  useEffect(() => {
    AirtableBase("residential_category_system")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setResidentialCategory(records);
      });
  }, []);

  useEffect(() => {
    AirtableBase("qualification_system")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setQualification(records);
      });
  }, []);

  useEffect(() => {
    const rec = [];
    AirtableBase("marketeers")
      .select({ view: "Grid view" })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            rec.push(record);
          });

          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          setMarketeers(rec);
        }
      );
  }, []);

  useEffect(() => {
    AirtableBase("fee_payer")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setFeePayer(records);
      });
  }, []);

  useEffect(() => {
    AirtableBase("uk_region")
      .select({ view: "Grid view" })
      .firstPage((err, records) => {
        setUKRegion(records);
      });
  }, []);

  const EmailExist = (email) => {
    return new Promise((resolve, reject) => {
      AirtableBase("applications")
        .select({
          view: "Grid view",
          filterByFormula: `({email_address} = '${email}')`,
        })
        .firstPage((err, records) => {
          if (err) reject(err);
          resolve(records);
        });
    });
  };

  const HandleValidation = async (field, form) => {
    let flag = 0;

    if (field === "partner" || form) {
      if (Student.partner === "") {
        flag++;
        HandleSetState(setErrorStudent, "partner", true);
      } else {
        HandleSetState(setErrorStudent, "partner", false);
      }
    }

    if (field === "title" || form) {
      if (Student.title === "") {
        flag++;
        HandleSetState(setErrorStudent, "title", true);
      } else {
        HandleSetState(setErrorStudent, "title", false);
      }
    }

    if (field === "first_name" || form) {
      if (
        Student.first_name === "" ||
        !Validator.isAlpha(Validator.blacklist(Student.first_name, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "first_name", true);
      } else {
        HandleSetState(setErrorStudent, "first_name", false);
      }
    }

    if (field === "last_name" || form) {
      if (
        Student.last_name === "" ||
        !Validator.isAlpha(Validator.blacklist(Student.last_name, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "last_name", true);
      } else {
        HandleSetState(setErrorStudent, "last_name", false);
      }
    }

    if (field === "gender" || form) {
      if (Student.gender === "") {
        flag++;
        HandleSetState(setErrorStudent, "gender", true);
      } else {
        HandleSetState(setErrorStudent, "gender", false);
      }
    }

    if (field === "date_of_birth" || form) {
      if (Student.date_of_birth === "") {
        flag++;
        HandleSetState(setErrorStudent, "date_of_birth", true);
      } else {
        HandleSetState(setErrorStudent, "date_of_birth", false);
      }
    }

    if (field === "country_of_birth" || form) {
      if (Student.country_of_birth === "") {
        flag++;
        HandleSetState(setErrorStudent, "country_of_birth", true);
      } else {
        HandleSetState(setErrorStudent, "country_of_birth", false);
      }
    }

    if (field === "nationality" || form) {
      if (Student.nationality === "") {
        flag++;
        HandleSetState(setErrorStudent, "nationality", true);
      } else {
        HandleSetState(setErrorStudent, "nationality", false);
      }
    }

    if (field === "ethnic_origin" || form) {
      if (Student.ethnic_origin === "") {
        flag++;
        HandleSetState(setErrorStudent, "ethnic_origin", true);
      } else {
        HandleSetState(setErrorStudent, "ethnic_origin", false);
      }
    }

    if (field === "email_address" || form) {
      if (
        Student.email_address === "" ||
        !Validator.isEmail(Student.email_address)
      ) {
        flag++;
        HandleSetState(setErrorStudent, "email_address", true);
      } else {
        const exist = await EmailExist(Student.email_address);
        if (exist.length > 0 && !applicationid) {
          flag++;
          alert("Email address exist in our system");
          HandleSetState(setErrorStudent, "email_address", true);
        } else {
          HandleSetState(setErrorStudent, "email_address", false);
        }
      }
    }

    if (field === "phone_number" || form) {
      if (
        Student.phone_number === "" ||
        !Validator.isNumeric(Student.phone_number)
      ) {
        flag++;
        HandleSetState(setErrorStudent, "phone_number", true);
      } else {
        HandleSetState(setErrorStudent, "phone_number", false);
      }
    }

    if (field === "marketing_officer" || form) {
      if (Student.marketing_officer === "") {
        flag++;
        HandleSetState(setErrorStudent, "marketing_officer", true);
      } else {
        HandleSetState(setErrorStudent, "marketing_officer", false);
      }
    }

    if (field === "address_line_1" || form) {
      if (Student.address_line_1 === "") {
        flag++;
        HandleSetState(setErrorStudent, "address_line_1", true);
      } else {
        HandleSetState(setErrorStudent, "address_line_1", false);
      }
    }

    if (field === "city" || form) {
      if (
        Student.city === "" ||
        !Validator.isAlpha(Validator.blacklist(Student.city, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "city", true);
      } else {
        HandleSetState(setErrorStudent, "city", false);
      }
    }

    if (field === "postcode" || form) {
      if (Student.postcode === "") {
        flag++;
        HandleSetState(setErrorStudent, "postcode", true);
      } else {
        HandleSetState(setErrorStudent, "postcode", false);
      }
    }

    if (field === "area_of_residence" || form) {
      if (Student.area_of_residence === "") {
        flag++;
        HandleSetState(setErrorStudent, "area_of_residence", true);
      } else {
        HandleSetState(setErrorStudent, "area_of_residence", false);
      }
    }

    if (field === "residential_category" || form) {
      if (Student.residential_category === "") {
        flag++;
        HandleSetState(setErrorStudent, "residential_category", true);
      } else {
        HandleSetState(setErrorStudent, "residential_category", false);
      }
    }

    if (field === "personal_statement" || form) {
      if (Student.personal_statement === "") {
        flag++;
        HandleSetState(setErrorStudent, "personal_statement", true);
      } else {
        HandleSetState(setErrorStudent, "personal_statement", false);
      }
    }

    if (field === "school_college" || form) {
      if (Student.school_college === "") {
        flag++;
        HandleSetState(setErrorStudent, "school_college", true);
      } else {
        HandleSetState(setErrorStudent, "school_college", false);
      }
    }

    if (field === "highest_qualification" || form) {
      if (Student.highest_qualification === "") {
        flag++;
        HandleSetState(setErrorStudent, "highest_qualification", true);
      } else {
        HandleSetState(setErrorStudent, "highest_qualification", false);
      }
    }

    if (field === "country_of_study" || form) {
      if (Student.country_of_study === "") {
        flag++;
        HandleSetState(setErrorStudent, "country_of_study", true);
      } else {
        HandleSetState(setErrorStudent, "country_of_study", false);
      }
    }

    if (field === "disability" || form) {
      if (Student.disability === "") {
        flag++;
        HandleSetState(setErrorStudent, "disability", true);
      } else {
        HandleSetState(setErrorStudent, "disability", false);
      }
    }

    if (field === "fee_payer" || form) {
      if (Student.fee_payer === "") {
        flag++;
        HandleSetState(setErrorStudent, "fee_payer", true);
      } else {
        HandleSetState(setErrorStudent, "fee_payer", false);
      }
    }

    if (field === "signature" || form) {
      if (
        Student.signature === "" ||
        !Validator.isAlpha(Validator.blacklist(Student.signature, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "signature", true);
      } else {
        HandleSetState(setErrorStudent, "signature", false);
      }
    }

    return flag;
  };

  const HandleSubmit = async (e) => {
    setBackDropOpen(true);
    e.preventDefault();
    const flag = await HandleValidation("", true);

    if (flag === 0) {
      if (applicationid) {
        AirtableBase("applications").update(
          [
            {
              id: applicationid,
              fields: {
                title: Student.title,
                first_name: Student.first_name,
                last_name: Student.last_name,
                gender: Student.gender,
                date_of_birth: Student.date_of_birth,
                country_of_birth: [Student.country_of_birth],
                nationality: [Student.nationality],
                ethnic_origin: [Student.ethnic_origin],
                marketing_officer: [Student.marketing_officer],
                address_line_1: Student.address_line_1,
                address_line_2: Student.address_line_2,
                address_line_3: Student.address_line_3,
                address_line_4: Student.address_line_4,
                postcode: Student.postcode,
                area_of_residence: [Student.area_of_residence],
                residential_category: [Student.residential_category],
                uk_entry_date: Student.uk_entry_date,
                personal_statement: Student.personal_statement,
                school_college: Student.school_college,
                highest_qualification: [Student.highest_qualification],
                country_of_study: [Student.country_of_study],
                disability: [Student.disability],
                fee_payer: [Student.fee_payer],
                declaration: true,
                signature: Student.signature,
                city: Student.city,
              },
            },
          ],
          (err, records) => {
            if (err) {
              console.error(err);
              return;
            }
            if (records) {
              setAlertType("success");
              setAlertMessage("Application successfully updated");
              setAlertOpen(true);
              history.push("/dashboard/applications");
            }
            setBackDropOpen(false);
          }
        );
      } else {
        AirtableBase("applications").create(
          [
            {
              fields: {
                title: Student.title,
                first_name: Student.first_name,
                last_name: Student.last_name,
                gender: Student.gender,
                date_of_birth: Student.date_of_birth,
                country_of_birth: [Student.country_of_birth],
                nationality: [Student.nationality],
                ethnic_origin: [Student.ethnic_origin],
                email_address: Student.email_address,
                phone_number: Student.phone_number,
                marketing_officer: [Student.marketing_officer],
                address_line_1: Student.address_line_1,
                address_line_2: Student.address_line_2,
                address_line_3: Student.address_line_3,
                address_line_4: Student.address_line_4,
                postcode: Student.postcode,
                area_of_residence: [Student.area_of_residence],
                residential_category: [Student.residential_category],
                uk_entry_date: Student.uk_entry_date,
                personal_statement: Student.personal_statement,
                school_college: Student.school_college,
                highest_qualification: [Student.highest_qualification],
                country_of_study: [Student.country_of_study],
                disability: [Student.disability],
                fee_payer: [Student.fee_payer],
                declaration: true,
                signature: Student.signature,
                agent: [localStorage.getItem("MarketeerId")],
                partner: [Student.partner],
                course: [Student.course],
                campus: [Student.campus],
                city: Student.city,
              },
            },
          ],
          { typecast: true },
          function (err, records) {
            if (err) {
              console.error(err);
              return;
            }
            if (records) {
              setAlertType("success");
              setAlertMessage("Application successfully created");
              setAlertOpen(true);
              history.push("/dashboard/applications");
            }
            setBackDropOpen(false);
          }
        );
      }
    } else {
      setBackDropOpen(false);
    }
  };

  const HandleChange = (e) => {
    HandleSetState(setStudent, e.target.name, e.target.value);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  const HandlePartnerChange = (e) => {
    HandleChange(e);
    if (e.target.value === "recV05qS7C7mZFWiL") {
      HandleSetState(setStudent, "campus", "recsYxYNj3lonWvze");
      HandleSetState(setStudent, "course", "recL0ezVzDsYRtGiO");
    } else if (e.target.value === "recbz9XOIyzCOGWb8") {
      HandleSetState(setStudent, "campus", "recLRNqBzkSURK0jj");
      HandleSetState(setStudent, "course", "recL0ezVzDsYRtGiO");
    }
  };

  return (
    <Box mx="0" my="100px">
      <Container
        maxWidth="lg"
        style={{ backgroundColor: "#ffffff", padding: "40px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h4" style={{ marginTop: "7px" }}>
              Student Application Form
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                window.history.back();
              }}
            >
              Go to applications
            </Button>
          </Grid>
        </Grid>

        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.partner}
            >
              <InputLabel id="partner_label">Select the partner</InputLabel>
              <Select
                labelId="partner_label"
                name="partner"
                value={Student.partner}
                onChange={HandlePartnerChange}
                onBlur={HandleBlur}
                label="Partner"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Partner.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select partner university applying for
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5">Personal details</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.title}
            >
              <InputLabel id="title_label">Select the title</InputLabel>
              <Select
                labelId="title_label"
                name="title"
                value={Student.title}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Title"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Title.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's Title</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="first_name"
              color="secondary"
              label="Forenames"
              variant="outlined"
              value={Student.first_name}
              fullWidth
              helperText="Please enter student's Forename"
              error={ErrorStudent.first_name}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="last_name"
              color="secondary"
              label="Surname"
              variant="outlined"
              value={Student.last_name}
              fullWidth
              helperText="Please enter student's Surname"
              error={ErrorStudent.last_name}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.gender}
            >
              <InputLabel id="gender_label">Select the Gender</InputLabel>
              <Select
                labelId="gender_label"
                name="gender"
                value={Student.gender}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Gender"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Gender.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's Gender</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                color="secondary"
                name="date_of_birth"
                fullWidth
                helperText="Please select the date of birth"
                inputVariant="outlined"
                label="Date of birth"
                value={Student.date_of_birth}
                maxDate={new Date()}
                minDate={new Date("1950-01-01")}
                error={ErrorStudent.date_of_birth}
                onChange={(date) => {
                  HandleSetState(setStudent, "date_of_birth", date);
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                color="secondary"
                name="uk_entry_date"
                fullWidth
                helperText="Please select the date of UK entry"
                inputVariant="outlined"
                label="UK entry date"
                value={Student.uk_entry_date}
                maxDate={new Date()}
                minDate={new Date("1950-01-01")}
                error={ErrorStudent.uk_entry_date}
                onChange={(date) => {
                  HandleSetState(setStudent, "uk_entry_date", date);
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.country_of_birth}
            >
              <InputLabel id="country_of_birth_label">
                Select the Country of birth
              </InputLabel>
              <Select
                labelId="country_of_birth_label"
                name="country_of_birth"
                value={Student.country_of_birth}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Country of birth"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Country.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select student's Country of birth
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.nationality}
            >
              <InputLabel id="nationality_label">
                Select the Nationality
              </InputLabel>
              <Select
                labelId="nationality_label"
                name="nationality"
                value={Student.nationality}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Nationality"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Nationality.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select student's Nationality
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.ethnic_origin}
            >
              <InputLabel id="ethnic_origin_label">
                Select the Ethnic Origin
              </InputLabel>
              <Select
                labelId="ethnic_origin_label"
                name="ethnic_origin"
                value={Student.ethnic_origin}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Ethnic origin"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Ethnicity.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's Etnicity</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5">Contact details</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              name="email_address"
              color="secondary"
              label="Email address"
              variant="outlined"
              value={Student.email_address}
              fullWidth
              helperText="Please enter student's Email address"
              error={ErrorStudent.email_address}
              disabled={applicationid ? true : false}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="phone_number"
              color="secondary"
              label="Phone number"
              variant="outlined"
              value={Student.phone_number}
              fullWidth
              helperText="Please enter student's Phone number"
              error={ErrorStudent.phone_number}
              disabled={applicationid ? true : false}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.marketing_officer}
            >
              <InputLabel id="marketeer_label">
                Select the Marketing Officer
              </InputLabel>
              <Select
                labelId="marketeer_label"
                name="marketing_officer"
                value={Student.marketing_officer}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Marketing Officer"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Marketeers.map((value, key) => {
                  if (value.fields.type.includes("Marketeer")) {
                    return (
                      <MenuItem key={key} value={value.id}>
                        {value.fields.name}
                      </MenuItem>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </Select>
              <FormHelperText>
                Please select student's Marketing officer
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5">Address details</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              name="address_line_1"
              color="secondary"
              label="Address Line 1"
              variant="outlined"
              value={Student.address_line_1}
              fullWidth
              helperText="Please enter student's address line 1"
              error={ErrorStudent.address_line_1}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="address_line_2"
              color="secondary"
              label="Address Line 2"
              variant="outlined"
              value={Student.address_line_2}
              fullWidth
              helperText="Please enter student's address line 2"
              error={ErrorStudent.address_line_2}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="address_line_3"
              color="secondary"
              label="Address Line 3"
              variant="outlined"
              value={Student.address_line_3}
              fullWidth
              helperText="Please enter student's address line 3"
              error={ErrorStudent.address_line_3}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="address_line_4"
              color="secondary"
              label="Address Line 4"
              variant="outlined"
              value={Student.address_line_4}
              fullWidth
              helperText="Please enter student's address line 4"
              error={ErrorStudent.address_line_4}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="city"
              color="secondary"
              label="City"
              variant="outlined"
              value={Student.city}
              fullWidth
              helperText="Please enter student's city"
              error={ErrorStudent.city}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="postcode"
              color="secondary"
              label="Postcode"
              variant="outlined"
              value={Student.postcode}
              fullWidth
              helperText="Please enter student's postcode"
              error={ErrorStudent.postcode}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5">Personal statement</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              name="personal_statement"
              color="secondary"
              label="Personal statement"
              variant="outlined"
              value={Student.personal_statement}
              fullWidth
              helperText="Please enter student's personal statement"
              error={ErrorStudent.personal_statement}
              onChange={HandleChange}
              onBlur={HandleBlur}
              multiline
              row={10}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5">Educational qualification</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              name="school_college"
              color="secondary"
              label="School/College"
              variant="outlined"
              value={Student.school_college}
              fullWidth
              helperText="Please enter student's school or college"
              error={ErrorStudent.school_college}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.highest_qualification}
            >
              <InputLabel id="highest_qualification_label">
                Select highest qualification
              </InputLabel>
              <Select
                labelId="highest_qualification_label"
                name="highest_qualification"
                value={Student.highest_qualification}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Highest qualification"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Qualification.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Pleas e select student's highest qualification
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.country_of_study}
            >
              <InputLabel id="country_of_study_label">
                Select the Country of study
              </InputLabel>
              <Select
                labelId="country_of_study_label"
                name="country_of_study"
                value={Student.country_of_study}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Country of study"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Country.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select student's Country of study
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5">Additional details</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.area_of_residence}
            >
              <InputLabel id="area_of_residence_label">
                Select the Area of permanent residence
              </InputLabel>
              <Select
                labelId="area_of_residence_label"
                name="area_of_residence"
                value={Student.area_of_residence}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Area of residence"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {UKRegion.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.county}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select student's area of permanent residence
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.residential_category}
            >
              <InputLabel id="residential_category_label">
                Select residential category
              </InputLabel>
              <Select
                labelId="residential_category_label"
                name="residential_category"
                value={Student.residential_category}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Residential category"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {ResidentialCategory.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select student's residential category
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.disability}
            >
              <InputLabel id="disability_label">Select disability</InputLabel>
              <Select
                labelId="disability_label"
                name="disability"
                value={Student.disability}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Disability"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Disability.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select student's disability
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.fee_payer}
            >
              <InputLabel id="fee_payer_label">Select the Fee payer</InputLabel>
              <Select
                labelId="fee_payer_label"
                name="fee_payer"
                value={Student.fee_payer}
                onChange={HandleChange}
                onBlur={HandleBlur}
                label="Fee payer"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {FeePayer.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.fields.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select student's Fee payer</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Box>
          {Student.partner === "recbz9XOIyzCOGWb8" ? (
            <Typography>
              <strong>DECLARATION:</strong> By submitting this application you
              confirm that the information given on this form is true, complete
              and accurate and no information or other material information has
              been omitted. You accept that if this is not the case, London
              School of Commerce, Canterbury Christ Church University or UCAS
              shall have the right to cancel your application and you shall have
              no claim against any higher education institution or college or
              UCAS in relation thereto. You give your consent to the processing
              of your data by the London School of Commerce, Canterbury Christ
              Church University and your data will be shared with UCAS and
              processed in accordance with their Privacy policy including use
              for statistical purposes (
              <a href="https://www.ucas.com/about-us/policies/privacy-policies-and-declarations/ucas-privacy-policy">
                Privacy policy
              </a>
              ). You also accept that having completed this form you do not have
              the right to further choices within the UCAS scheme and will not
              approach any other university or college in the UCAS scheme. You
              acknowledge that further information may be requested by the
              London School of Commerce and/or Canterbury Christ Church
              University in order to process your application. You have the
              right to cancel this application. If you decide not to take up
              your place at the London School of Commerce you must do this by
              informing the university or college as soon as possible.
            </Typography>
          ) : (
            <Typography>
              <strong>DECLARATION:</strong> By submitting this application you
              confirm that the information given on this form is true, complete
              and accurate and no information or other material information has
              been omitted. You accept that if this is not the case, we shall
              have the right to cancel your application and you shall have no
              claim against any higher education institution or college. You
              give your consent to the processing of your data by the London
              School of Commerce and your data will be shared with the
              University and processed in accordance with their Privacy policy.
              You acknowledge that further information may be requested by the
              London School of Commerce in order to process your application.
              You have the right to cancel this application. If you decide not
              to take up your place at the London School of Commerce you must do
              this by informing the university or college as soon as possible.
            </Typography>
          )}
        </Box>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="signature"
              color="secondary"
              label="Signature"
              variant="outlined"
              value={Student.signature}
              fullWidth
              helperText="Please enter student's full name"
              error={ErrorStudent.signature}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                color="secondary"
                name="declaration_date"
                fullWidth
                inputVariant="outlined"
                label="Date"
                value={new Date()}
                disabled
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <br />
        <br />
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={HandleSubmit}
        >
          Save application
        </Button>
      </Container>
    </Box>
  );
};
