import React, { useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import {
  AppBar,
  Backdrop,
  CircularProgress,
  Dialog,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import AirtableBase from "../airtable";
import Moment from "moment";

import NavigationComponent from "./navigation";
import NewAppointmentComponent from "./new-appointment";
import ViewAppointmentComponent from "./view-appointments";
import ViewInterviewsComponent from "./view-interviews";
import AppointmentsReportsComponent from "./appointments-reports";
import AppointmentsReports2021Component from "./appointments-reports-2021";
import AppointmentsReportsDecember2020Component from "./appointments-reports-december-2020";
import AdministratorReportComponent from "./administrator-reports";
import PqpReportComponent from "./pqp-report";
import PqpReport2021Component from "./pqp-report-2021";
import UobPqpReportsComponent from "./uob-pqp-reports";
import UobPqpReports2021Component from "./uob-pqp-reports-2021";
import UobPqpReportsDecember2020Component from "./uob-pqp-reports-december-2020";
import RebookComponent from "./rebook";
import ReportComponent from "./reports";
import InterviewersReport from "./interviewers-report";
import InterviewersReport2021 from "./interviewers-report-2021";
import InterviewersReportDecember2020Component from "./interviewers-report-december-2020";
import RegistrationComponent from "./registration-form";
import ApplicationsComponent from "./applications";
import AdministratorApplicationsComponent from "./administrator_applications";
import UploadDocuments from "./upload-documents";
import ScreeningComponent from "./screening";
import ChangePasswordComponent from "./change_password";
import AppointmentComponent from "./appointment";
import ApplicationReport from "./application-reports";

import __, { isUndefined } from "lodash";
import FeedbackComponent from "./feedback";

const DashboardComponent = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [MarketeerId] = useState(localStorage.getItem("MarketeerId"));
  const [InterviewStudent, setInterviewStudent] = useState([]);
  const [Interviews, setInterviews] = useState([]);

  const [Marketeer, setMarketeer] = useState({});
  const [Interviewer, setInterviewer] = useState({});
  const [BackDropOpen, setBackDropOpen] = useState(true);
  const [DialogOpen, setDialogOpen] = useState(false);

  const [AlertOpen, setAlertOpen] = useState(false);
  const [AlertType, setAlertType] = useState("info");
  const [AlertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const version = localStorage.getItem("version");
    if (version !== "7") {
      history.push("/");
      localStorage.clear();
      window.location.reload();
    }
  });

  useEffect(() => {
    setBackDropOpen(true);
    AirtableBase("marketeers").find(MarketeerId, async (err, record) => {
      setMarketeer(record.fields);
      const interviewer = __.find(
        JSON.parse(localStorage.getItem("interviewers")),
        { email: record.fields.email }
      );
      if (!isUndefined(interviewer)) {
        await AirtableBase("interviewer").find(
          interviewer.id,
          function (err, record) {
            setInterviewer(record.fields);
            GetInterviews(record.fields.email, "all");
          }
        );
      }
      setBackDropOpen(false);
    });
    GetAppointments();
  }, [MarketeerId, Marketeer.email]);

  const GetInterviews = async (email, type) => {
    const StudentArray = [];
    const formulae =
      type === "today"
        ? "AND({confirmed}, {interviewer email} = '" +
          email +
          "', {date}=TODAY())"
        : "({interviewer email} = '" + email + "')";
    await AirtableBase("students")
      .select({
        filterByFormula: formulae,
        sort: [
          { field: "date", direction: "asc" },
          { field: "interview time", direction: "asc" },
        ],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (students) {
            const student = students.fields;
            StudentArray.push({
              id: students.id,
              code: student["code"],
              first_name: student["first name"],
              last_name: student["last name"],
              name: student["first name"] + " " + student["last name"],
              email: student["email"],
              phone: student["phone"],
              agent: student["agent"],
              campus: student["campus"][0],
              course: student["course"][0],
              partner: student["partner"][0],
              date: Moment(student["date"]).format("DD/MM/YYYY"),
              marketeer: student["marketeer"][0],
              slot: student["time"][0],
              interviewed: student["interviewed"],
              feedback: student["feedback"],
              confirmed: student["confirmed"],
              MaritalStatus: student["MaritalStatus"],
              ClassPreference: student["ClassPreference"],
              pqp_test: student["pqp test"],
              english_test: student["english test"],
              english_test_retake: student["english test retake"],
              pqp_test_retake: student["pqp_test_retake"],
              documents: student["documents"],
              comments: student["comments"],
              rejection_email: student["rejection_email"],
              "Old Rebook Ref": student["Old Rebook Ref"],
              "english test done": student["english test done"],
              "Approved by TA": student["Approved by TA"],
              "Rejected by TA": student["Rejected by TA"],
              "group name": student["group name"],
            });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          setInterviews(StudentArray);
        }
      );
  };

  const GetAppointments = async () => {
    const StudentArray = [];
    await AirtableBase("students")
      .select({
        view: "Grid view",
        filterByFormula: "({marketeer email} = '" + Marketeer.email + "')",
        sort: [
          { field: "date", direction: "asc" },
          { field: "interview time", direction: "asc" },
        ],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (students) {
            const student = students.fields;
            StudentArray.push({
              id: students.id,
              code: student["code"],
              name: student["first name"] + " " + student["last name"],
              email: student["email"],
              phone: student["phone"],
              agent: student["agent"],
              campus: student["campus"][0],
              course: student["course"][0],
              partner: student["partner"][0],
              date: Moment(student["date"]).format("DD/MM/YYYY"),
              interviewer: student["interviewer"][0],
              slot: student["time"][0],
              confirmed: student["confirmed"],
              interviewed: student["interviewed"],
              feedback: student["feedback"],
              comments: student["comments"],
              agentEmail: student["agent email address"],
              documents: student["documents"],
              rebook: student["Rebook"],
              pqp_feedback: student["pqp feedback"],
              "Approved by TA": student["Approved by TA"],
              "Rejected by TA": student["Rejected by TA"],
              "group name": student["group name"],
              "university id": student["university id"],
            });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          setInterviewStudent(StudentArray);
        }
      );
  };

  const HandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <AppBar position="fixed" color="secondary">
        <NavigationComponent
          Marketeer={Marketeer}
          Interviewer={Interviewer}
          setDialogOpen={setDialogOpen}
        ></NavigationComponent>
      </AppBar>
      <Dialog open={DialogOpen} maxWidth="md" fullWidth>
        <NewAppointmentComponent
          MarketeerId={MarketeerId}
          setMarketeer={setMarketeer}
          setBackDropOpen={setBackDropOpen}
          setDialogOpen={setDialogOpen}
          setAlertOpen={setAlertOpen}
          setAlertType={setAlertType}
          setAlertMessage={setAlertMessage}
          GetAppointments={GetAppointments}
        ></NewAppointmentComponent>
      </Dialog>
      <HashRouter>
        <Switch>
          <Route path={`${match.path}/appointments`}>
            <ViewAppointmentComponent
              Marketeer={Marketeer}
              InterviewStudent={InterviewStudent}
              GetAppointments={GetAppointments}
              setBackDropOpen={setBackDropOpen}
            ></ViewAppointmentComponent>
          </Route>
          <Route path={`${match.path}/interviews/:type`}>
            <ViewInterviewsComponent
              Interviewer={Interviewer}
              Interviews={Interviews}
              GetInterviews={GetInterviews}
            ></ViewInterviewsComponent>
          </Route>
          <Route path={`${match.path}/feedback/:student`}>
            <FeedbackComponent
              Interviewer={Interviewer}
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></FeedbackComponent>
          </Route>
          <Route path={`${match.path}/rebook/:getstudent`}>
            <RebookComponent
              Marketeer={Marketeer}
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
              GetAppointments={GetAppointments}
            ></RebookComponent>
          </Route>
          <Route path={`${match.path}/reports`}>
            <ReportComponent Interviewer={Interviewer}></ReportComponent>
          </Route>
          <Route path={`${match.path}/appointment-reports`}>
            <AppointmentsReportsComponent Interviewer={Interviewer} />
          </Route>
          <Route path={`${match.path}/appointments-reports-2021`}>
            <AppointmentsReports2021Component Interviewer={Interviewer} />
          </Route>
          <Route path={`${match.path}/appointment-reports-december-2020`}>
            <AppointmentsReportsDecember2020Component
              Interviewer={Interviewer}
            />
          </Route>
          <Route path={`${match.path}/administrator-reports`}>
            <AdministratorReportComponent
              Interviewer={Interviewer}
            ></AdministratorReportComponent>
          </Route>
          <Route path={`${match.path}/administrator-reports`}>
            <ApplicationReport></ApplicationReport>
          </Route>
          <Route path={`${match.path}/pqp-report`}>
            <PqpReportComponent />
          </Route>
          <Route path={`${match.path}/pqp-report-2021`}>
            <PqpReport2021Component />
          </Route>
          <Route path={`${match.path}/uob-pqp-reports`}>
            <UobPqpReportsComponent />
          </Route>
          <Route path={`${match.path}/uob-pqp-reports-2021`}>
            <UobPqpReports2021Component />
          </Route>
          <Route path={`${match.path}/uob-pqp-reports-december-2020`}>
            <UobPqpReportsDecember2020Component />
          </Route>
          <Route path={`${match.path}/interviewers-report`}>
            <InterviewersReport></InterviewersReport>
          </Route>
          <Route path={`${match.path}/interviewers-report-2021`}>
            <InterviewersReport2021 />
          </Route>
          <Route path={`${match.path}/interviewers-report-december-2020`}>
            <InterviewersReportDecember2020Component />
          </Route>
          <Route path={`${match.path}/registration/:applicationid`}>
            <RegistrationComponent
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></RegistrationComponent>
          </Route>
          <Route path={`${match.path}/registration`}>
            <RegistrationComponent
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></RegistrationComponent>
          </Route>

          <Route path={`${match.path}/applications`}>
            <ApplicationsComponent
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></ApplicationsComponent>
          </Route>

          <Route path={`${match.path}/administrator-applications`}>
            <AdministratorApplicationsComponent
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></AdministratorApplicationsComponent>
          </Route>

          <Route path={`${match.path}/upload-documents/:applicationid`}>
            <UploadDocuments
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></UploadDocuments>
          </Route>

          <Route path={`${match.path}/change-password`}>
            <ChangePasswordComponent></ChangePasswordComponent>
          </Route>

          <Route path={`${match.path}/screening/:applicationid`}>
            <ScreeningComponent
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
            ></ScreeningComponent>
          </Route>
          <Route path={`${match.path}/appointment/:applicationid`}>
            <AppointmentComponent
              setAlertOpen={setAlertOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setBackDropOpen={setBackDropOpen}
              GetAppointments={GetAppointments}
            ></AppointmentComponent>
          </Route>
        </Switch>
      </HashRouter>
      <Backdrop open={BackDropOpen} style={{ color: "#fff", zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={AlertOpen} autoHideDuration={6000} onClose={HandleClose}>
        <Alert severity={AlertType}>{AlertMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default DashboardComponent;
