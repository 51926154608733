import React, { useState, useEffect } from "react";
import {
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  OutlinedInput,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Validator from "validator";

import axios from "axios";

import AirtableBase from "../airtable";
import Moment from "moment";
import __ from "lodash";

const NewAppointmentComponent = ({
  MarketeerId,
  setBackDropOpen,
  setDialogOpen,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
  GetAppointments,
}) => {
  const [Campus, setCampus] = useState([]);

  const [Partner, setPartner] = useState([]);
  const [PartnerDisable, setPartnerDisable] = useState(true);

  const [Course, setCourse] = useState([]);
  const [CourseDisable, setCourseDisable] = useState(true);

  const [Interviewer, setInterviewer] = useState([]);
  const [InterviewerDisable, setInterviewerDisable] = useState(true);

  const [Time, setTime] = useState([]);
  const [TimeDisable, setTimeDisable] = useState(true);

  const [DateDisable, setDateDisable] = useState(true);
  const [DatePickerMaxDate, setDatePickerMaxDate] = useState(new Date());
  const [DatePickerMinDate, setDatePickerMinDate] = useState(new Date());

  const [Documents, setDocuments] = useState({});

  const [InterviewerStorage] = useState(
    JSON.parse(localStorage.getItem("interviewers"))
  );

  const [DisableDateArray, setDisableDateArray] = useState([]);
  const [DisableBothDateArray, setDisableBothDateArray] = useState([]);
  const [DisableBothTimeArray, setDisableBothTimeArray] = useState([]);

  const [Student, setStudent] = useState({
    ID: "",
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    PhoneNumber: "",
    Code: "",
    Agent: "",
    AgentEmailAddress: "",
    Campus: "",
    Partner: "",
    Course: "",
    Interviewer: "",
    Time: "",
    Documents: "",
    Date: new Date(),
  });

  const [ErrorStudent, setErrorStudent] = useState({
    FirstName: false,
    LastName: false,
    EmailAddress: false,
    PhoneNumber: false,
    Code: false,
    Agent: false,
    AgentEmailAddress: false,
    Campus: false,
    Partner: false,
    Course: false,
    Date: false,
    Time: false,
    Documents: false,
    Interviewer: false,
  });

  useEffect(() => {
    setBackDropOpen(true);
    AirtableBase("campus")
      .select({
        view: "Grid view",
      })
      .firstPage(function (err, records) {
        const campuses = [];
        records.forEach(function (record) {
          campuses.push({ id: record.id, name: record.get("name") });
        });
        setCampus(campuses);
        setBackDropOpen(false);
      });
  }, [setBackDropOpen]);

  const HandleValidation = async (field, form) => {
    let flag = 0;

    if (field === "Code" || form) {
      if (
        Student.Code === "" ||
        !Validator.isAlphanumeric(Validator.blacklist(Student.Code, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "Code", true);
      } else {
        HandleSetState(setErrorStudent, "Code", false);
      }
    }

    if (field === "FirstName" || form) {
      if (
        Student.FirstName === "" ||
        !Validator.isAlpha(Validator.blacklist(Student.FirstName, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "FirstName", true);
      } else {
        HandleSetState(setErrorStudent, "FirstName", false);
      }
    }
    if (field === "LastName" || form) {
      if (
        Student.LastName === "" ||
        !Validator.isAlpha(Validator.blacklist(Student.LastName, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "LastName", true);
      } else {
        HandleSetState(setErrorStudent, "LastName", false);
      }
    }
    if (field === "EmailAddress" || form) {
      if (
        Student.EmailAddress === "" ||
        !Validator.isEmail(Student.EmailAddress)
      ) {
        flag++;
        HandleSetState(setErrorStudent, "EmailAddress", true);
      } else {
        HandleSetState(setErrorStudent, "EmailAddress", false);
      }
    }
    if (field === "PhoneNumber" || form) {
      if (
        Student.PhoneNumber === "" ||
        !Validator.isNumeric(Student.PhoneNumber)
      ) {
        flag++;
        HandleSetState(setErrorStudent, "PhoneNumber", true);
      } else {
        HandleSetState(setErrorStudent, "PhoneNumber", false);
      }
    }

    if (field === "Agent" || form) {
      if (
        Student.Agent === "" ||
        !Validator.isAlphanumeric(Validator.blacklist(Student.Agent, " "))
      ) {
        flag++;
        HandleSetState(setErrorStudent, "Agent", true);
      } else {
        HandleSetState(setErrorStudent, "Agent", false);
      }
    }

    if (Student.AgentEmailAddress !== "") {
      if (!Validator.isEmail(Student.AgentEmailAddress)) {
        flag++;
        HandleSetState(setErrorStudent, "AgentEmailAddress", true);
      } else {
        HandleSetState(setErrorStudent, "AgentEmailAddress", false);
      }
    }

    if (field === "Campus" || form) {
      if (Student.Campus === "") {
        flag++;
        HandleSetState(setErrorStudent, "Campus", true);
      } else {
        HandleSetState(setErrorStudent, "Campus", false);
      }
    }

    if (field === "Partner" || form) {
      if (Student.Partner === "") {
        flag++;
        HandleSetState(setErrorStudent, "Partner", true);
      } else {
        HandleSetState(setErrorStudent, "Partner", false);
      }
    }

    if (field === "Course" || form) {
      if (Student.Course === "") {
        flag++;
        HandleSetState(setErrorStudent, "Course", true);
      } else {
        HandleSetState(setErrorStudent, "Course", false);
      }
    }

    if (field === "Interviewer" || form) {
      if (Student.Interviewer === "") {
        flag++;
        HandleSetState(setErrorStudent, "Interviewer", true);
      } else {
        HandleSetState(setErrorStudent, "Interviewer", false);
      }
    }

    if (field === "Date" || form) {
      const today = new Date();
      const selection = new Date(Student.Date);
      if (selection === today) {
        flag++;
        HandleSetState(setErrorStudent, "Date", true);
      } else {
        HandleSetState(setErrorStudent, "Date", false);
      }
    }

    if (field === "Time" || form) {
      if (Student.Time === "") {
        flag++;
        HandleSetState(setErrorStudent, "Time", true);
      } else {
        HandleSetState(setErrorStudent, "Time", false);
      }
    }

    if (field === "Document" || form) {
      if (Student.Documents === "") {
        flag++;
        HandleSetState(setErrorStudent, "Documents", true);
      } else {
        HandleSetState(setErrorStudent, "Documents", false);
      }
    }
    return flag;
  };

  const HandleCampusChange = async (e) => {
    if (e.target.value !== "") {
      setBackDropOpen(true);
      HandleChange(e);

      HandleSetState(setStudent, "Partner", "");
      HandleSetState(setStudent, "Course", "");
      HandleSetState(setStudent, "Interviewer", "");
      HandleSetState(setStudent, "Date", new Date());
      HandleSetState(setStudent, "Time", "");

      setPartnerDisable(true);
      setCourseDisable(true);
      setInterviewerDisable(true);
      setDateDisable(true);
      setTimeDisable(true);

      setPartner([]);
      setCourse([]);
      setInterviewer([]);
      setTime([]);

      await AirtableBase("partners")
        .select({ view: "Grid view" })
        .firstPage((err, records) => {
          const CampusArray = [];
          records.forEach((record) => {
            const RecordArray = record.fields.campus;
            if (RecordArray.includes(e.target.value)) {
              CampusArray.push({ id: record.id, name: record.fields.name });
            }
          });
          setPartner(CampusArray);
        });

      await AirtableBase("interviewer")
        .select({ view: "Grid view" })
        .firstPage((err, records) => {
          const InterviewerArray = [];
          records.forEach((record) => {
            if ("campus" in record.fields) {
              const RecordArray = record.fields.campus;
              if (RecordArray.includes(e.target.value)) {
                InterviewerArray.push({
                  id: record.id,
                  name: record.fields.name,
                });
              }
            }
          });
          setInterviewer(InterviewerArray);
        });
      setPartnerDisable(false);
      setInterviewerDisable(false);
      setBackDropOpen(false);
    }
  };

  const HandlePartnerChange = (e) => {
    if (e.target.value !== "") {
      setBackDropOpen(true);
      HandleChange(e);

      HandleSetState(setStudent, "Course", "");
      setCourseDisable(true);
      setCourse([]);

      AirtableBase("courses")
        .select({ view: "Grid view" })
        .firstPage(function (err, records) {
          const CourseArray = [];
          records.forEach(function (record) {
            const RecordArray = record.fields.partner;
            if (RecordArray.includes(e.target.value)) {
              CourseArray.push({ id: record.id, name: record.fields.name });
            }
          });
          setCourse(CourseArray);
          setBackDropOpen(false);
          setCourseDisable(false);
        });
    }
  };

  const HandleInterviewerChange = (e) => {
    if (e.target.value !== "") {
      setBackDropOpen(true);
      HandleChange(e);

      HandleSetState(setStudent, "Date", new Date());
      HandleSetState(setStudent, "Time", "");

      setDateDisable(true);
      setTimeDisable(true);

      setTime([]);

      AirtableBase("interviewer").find(e.target.value, function (err, record) {
        HandleSetState(
          setStudent,
          "Date",
          new Date(record.fields.available_from)
        );
        setDatePickerMinDate(record.fields.available_from);
        setDatePickerMaxDate(record.fields.available_to);
        if ("not available date" in record.fields) {
          if ("not available time" in record.fields) {
            const not_available_dates = record.get("not available date");
            setDisableBothDateArray(not_available_dates.split(","));
            setDisableBothTimeArray(record.get("not available time"));
            setDisableDateArray([]);
          } else {
            const not_available_dates = record.get("not available date");
            setDisableDateArray(not_available_dates.split(","));
            setDisableBothDateArray([]);
            setDisableBothTimeArray([]);
          }
        } else {
          setDisableBothDateArray([]);
          setDisableBothTimeArray([]);
          setDisableDateArray([]);
        }
        setBackDropOpen(false);
        setDateDisable(false);
      });
    }
  };

  const HandleDateChange = async (e) => {
    setBackDropOpen(true);
    HandleSetState(setStudent, "Time", "");
    setTimeDisable(true);
    setTime([]);
    HandleSetState(setStudent, "Date", e);
    const mydate = `${e.getFullYear()}-${e.getMonth() + 1}-${e.getDate()}`;
    const days = {
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    };
    const day = days[Moment(e).day()];
    const interviewer = __.find(InterviewerStorage, {
      id: Student.Interviewer,
    });
    const idate = Moment(e).format("YYYY/MM/DD");
    await AirtableBase("students")
      .select({
        view: "Grid view",
        filterByFormula: `AND({interviewer email} = '${interviewer.email}', {date} = DATETIME_PARSE('${idate}','YYYY/MM/DD'))`,
      })
      .firstPage(async (err, records) => {
        const unaavailable = [];
        await records.forEach((record) => {
          unaavailable.push({
            id: record.fields.time[0],
            time: record.fields["interview time"][0],
          });
        });
        await AirtableBase("slots")
          .select({
            view: "Grid view",
            filterByFormula: `AND({available}, FIND("${day}", days) > 0)`,
          })
          .firstPage(function (err, records) {
            let AllSlots = [];
            records.forEach(function (record) {
              if (
                !(
                  DisableBothDateArray.includes(mydate) &&
                  DisableBothTimeArray.includes(record.id)
                )
              ) {
                AllSlots.push({ id: record.id, time: record.fields.time });
              }
            });
            AllSlots = AllSlots.filter(
              (o) => !unaavailable.find((o2) => o.id === o2.id)
            );
            setTime(AllSlots);
            setTimeDisable(false);
            setBackDropOpen(false);
          });
      });
  };

  const HandleFile = (e) => {
    HandleSetState(setStudent, "Documents", e.target.files[0]);
  };

  const HandleUpload = async () => {
    const formData = new FormData();
    formData.append("file", Student.Documents);
    return await axios.post(
      "https://0arwzgjox3.execute-api.eu-west-2.amazonaws.com/applications/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  /*
  const HandleCrmBlur = async (event) => {
    HandleValidation(event.target.name, false);
    if (event.target.value !== "") {
      setBackDropOpen(true);
      const student = await axios.get(`https://mrr4077z0m.execute-api.us-east-2.amazonaws.com/student/${event.target.value}`);
      if (student.data.success) {
        const studentData = student.data.data;
        HandleSetState(setStudent, "FirstName", studentData.first_name);
        HandleSetState(setStudent, "LastName", studentData.last_name);
        HandleSetState(setStudent, "EmailAddress", studentData.email_address);
        HandleSetState(setStudent, "PhoneNumber", studentData.phone_number);
        HandleSetState(setStudent, "Agent", studentData.agent_name);
        if (studentData.agent_email) {
          HandleSetState(setStudent, "AgentEmailAddress", studentData.agent_email);
        }
        setBackDropOpen(false);
      } else {
        setBackDropOpen(false);
      }
    }
  };
  */

  const HandleChange = (e) => {
    HandleSetState(setStudent, e.target.name, e.target.value);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const flag = await HandleValidation("", true);
    if (flag === 0) {
      const partners = JSON.parse(localStorage.getItem("partners"));
      let result = __.find(partners, (partner) => {
        if (partner.id === Student.Partner) {
          return true;
        }
      });
      await AirtableBase("students")
        .select({
          view: "Grid view",
          filterByFormula: `AND({email} = '${Student.EmailAddress}', {partner name}='${result.name}')`,
        })
        .firstPage(async (err, records) => {
          if (records.length === 0) {
            setBackDropOpen(true);
            const upload = await HandleUpload();
            await AirtableBase("students").create(
              [
                {
                  fields: {
                    code: Student.Code,
                    "first name": Student.FirstName,
                    "last name": Student.LastName,
                    email: Student.EmailAddress,
                    phone: Student.PhoneNumber,
                    agent: Student.Agent,
                    marketeer: [MarketeerId],
                    campus: [Student.Campus],
                    partner: [Student.Partner],
                    course: [Student.Course],
                    date: Student.Date,
                    interviewer: [Student.Interviewer],
                    documents: upload.data.data.Location,
                    time: [Student.Time],
                    "agent email address": Student.AgentEmailAddress,
                  },
                },
              ],
              (err, records) => {
                HandleSetState(setStudent, "ID", records[0].id);
                setDialogOpen(false);
                setAlertType("success");
                setAlertMessage("Appointment successfully added");
                setAlertOpen(true);
                setBackDropOpen(false);
                setStudent({
                  ID: "",
                  FirstName: "",
                  LastName: "",
                  EmailAddress: "",
                  PhoneNumber: "",
                  Code: "",
                  Agent: "",
                  Campus: "",
                  Partner: "",
                  Course: "",
                  Interviewer: "",
                  Documents: "",
                  Time: "",
                  Date: new Date(),
                });
                GetAppointments();
              }
            );
          } else {
            HandleSetState(setErrorStudent, "EmailAddress", true);
            alert("The email address already exist in the system.");
          }
        });
    }
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  return (
    <form onSubmit={HandleSubmit} autoComplete="off" method="POST">
      <DialogTitle>Add an Appointment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the below details to book an appointment.{" "}
          <strong>
            All fields are required and No Special Characters Allowed
          </strong>
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              error={ErrorStudent.Campus}
            >
              <InputLabel id="campus-label">Select the campus</InputLabel>
              <Select
                labelId="campus-label"
                name="Campus"
                value={Student.Campus}
                onChange={HandleCampusChange}
                onBlur={HandleBlur}
                label="Campus"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Campus.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select the campus applying for
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={InterviewerDisable}
              error={ErrorStudent.Interviewer}
            >
              <InputLabel id="course-label">Select the interviewer</InputLabel>
              <Select
                labelId="course-label"
                name="Interviewer"
                value={Student.Interviewer}
                label="interviewer"
                onChange={HandleInterviewerChange}
                onBlur={HandleBlur}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Interviewer.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Please select the interviewer</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disablePast
                value={Student.Date}
                onChange={HandleDateChange}
                onBlur={() => {
                  HandleValidation("Date", false);
                }}
                onClose={() => {
                  HandleValidation("Date", false);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date of interview"
                inputVariant="outlined"
                label="Date of interview"
                shouldDisableDate={(date) => {
                  const mydate = `${date.getFullYear()}-${
                    date.getMonth() + 1
                  }-${date.getDate()}`;
                  return DisableDateArray.includes(mydate);
                }}
                maxDate={DatePickerMaxDate}
                minDate={DatePickerMinDate}
                disabled={DateDisable}
                error={ErrorStudent.Date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={TimeDisable}
              error={ErrorStudent.Time}
            >
              <InputLabel id="time-label">Select the time</InputLabel>
              <Select
                labelId="time-label"
                name="Time"
                value={Student.Time}
                label="time"
                onChange={HandleChange}
                onBlur={HandleBlur}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Time.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.time}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select the time of interview
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={PartnerDisable}
              error={ErrorStudent.Partner}
            >
              <InputLabel id="partner-label">Select the partner</InputLabel>
              <Select
                labelId="partner-label"
                name="Partner"
                value={Student.Partner}
                onChange={HandlePartnerChange}
                onBlur={HandleBlur}
                label="partner"
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Partner.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select the partner university
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={CourseDisable}
              error={ErrorStudent.Course}
            >
              <InputLabel id="course-label">Select the course</InputLabel>
              <Select
                labelId="course-label"
                name="Course"
                value={Student.Course}
                label="course"
                onChange={HandleChange}
                onBlur={HandleBlur}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {Course.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Please select the course applying for
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="Code"
              color="secondary"
              label="Code"
              value={Student.Code}
              fullWidth
              helperText="Please enter student's code"
              variant="outlined"
              error={ErrorStudent.Code}
              onChange={HandleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="FirstName"
              color="secondary"
              label="First name"
              variant="outlined"
              value={Student.FirstName}
              fullWidth
              helperText="Please enter student's first name"
              error={ErrorStudent.FirstName}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="LastName"
              color="secondary"
              label="Last name"
              value={Student.LastName}
              fullWidth
              helperText="Please enter student's last name"
              variant="outlined"
              error={ErrorStudent.LastName}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="EmailAddress"
              color="secondary"
              label="Email address"
              value={Student.EmailAddress}
              fullWidth
              helperText="Please enter student's email address"
              variant="outlined"
              error={ErrorStudent.EmailAddress}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="PhoneNumber"
              color="secondary"
              label="Phone number"
              value={Student.PhoneNumber}
              fullWidth
              helperText="Please enter student's phone number"
              variant="outlined"
              error={ErrorStudent.PhoneNumber}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="Agent"
              color="secondary"
              label="Agent"
              value={Student.Agent}
              fullWidth
              helperText="Please enter the name of the agent"
              variant="outlined"
              error={ErrorStudent.Agent}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="AgentEmailAddress"
              color="secondary"
              label="Agent email address"
              value={Student.AgentEmailAddress}
              fullWidth
              helperText="Please enter the email address of the agent"
              variant="outlined"
              error={ErrorStudent.AgentEmailAddress}
              onChange={HandleChange}
              onBlur={HandleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <OutlinedInput
              type="file"
              id="file"
              name="file"
              color="secondary"
              label="Upload ID Proof"
              error={ErrorStudent.Documents}
              onChange={HandleFile}
              fullWidth
            ></OutlinedInput>
            Please upload the identification document (ID)
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Submit
        </Button>
      </DialogActions>
    </form>
  );
};

export default NewAppointmentComponent;
