import React, { useState } from "react";
import { Toolbar, Typography, Button, Menu, MenuItem } from "@material-ui/core";
import { ExitToApp, ViewAgenda, SupervisorAccount, Person, Storage } from "@material-ui/icons";
const NavigationComponent = ({ Marketeer, setDialogOpen, Interviewer }) => {
  const style = {
    marginRight: "30px",
  };

  const [Access] = useState(JSON.parse(localStorage.getItem("access")));

  const [InterviewAnchor, setInterviewAnchor] = useState(null);
  const handleInterviewerClick = (event) => {
    setInterviewAnchor(event.currentTarget);
  };
  const handleInterviewerClose = () => {
    setInterviewAnchor(null);
  };

  const [AdministratorAnchor, setAdministratorAnchor] = useState(null);
  const handleAdministratorClick = (event) => {
    setAdministratorAnchor(event.currentTarget);
  };
  const handleAdministratorClose = () => {
    setAdministratorAnchor(null);
  };

  const [ApplicationAnchor, setApplicationAnchor] = useState(null);
  const handleApplicationClick = (event) => {
    setApplicationAnchor(event.currentTarget);
  };
  const handleApplicationClose = () => {
    setApplicationAnchor(null);
  };

  const [MarketeerAnchor, setMarketeerAnchor] = useState(null);
  const handleMarketeerClick = (event) => {
    setMarketeerAnchor(event.currentTarget);
  };
  const handleMarketeerClose = () => {
    setMarketeerAnchor(null);
  };

  return (
    <Toolbar>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        Welcome {Marketeer.name}
      </Typography>

      {Access.includes("Interviewer") ? (
        <>
          <Button
            aria-controls="simple-menu"
            color="inherit"
            aria-haspopup="true"
            onClick={handleInterviewerClick}
            style={style}
            startIcon={<SupervisorAccount />}
          >
            Interviewers
          </Button>
          <Menu id="simple-menu" anchorEl={InterviewAnchor} keepMounted open={Boolean(InterviewAnchor)} onClose={handleInterviewerClose}>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/interviews/all";
              }}
            >
              All Interviews
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/interviews/today";
              }}
            >
              Today's Interviews
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/reports";
              }}
            >
              Interview Reports
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/interviewers-report-2021";
              }}
            >
              Interviews report 2021
            </MenuItem>
          </Menu>
        </>
      ) : (
        ""
      )}

      {Access.includes("Administrator") ? (
        <>
          <Button
            aria-controls="simple-menu"
            color="inherit"
            aria-haspopup="true"
            onClick={handleAdministratorClick}
            style={style}
            startIcon={<ViewAgenda></ViewAgenda>}
          >
            Admin
          </Button>
          <Menu id="simple-menu" anchorEl={AdministratorAnchor} keepMounted open={Boolean(AdministratorAnchor)} onClose={handleAdministratorClose}>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/pqp-report";
              }}
            >
              PQP Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/pqp-report-2021";
              }}
            >
              PQP Report 2021
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/uob-pqp-reports";
              }}
            >
              UOB PQP Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/uob-pqp-reports-2021";
              }}
            >
              UOB PQP Report 2021
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/uob-pqp-reports-december-2020";
              }}
            >
              UOB PQP Report December 2020
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/administrator-reports";
              }}
            >
              Administrator Reports
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/interviewers-report";
              }}
            >
              Interviewers report
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/interviewers-report-2021";
              }}
            >
              Interviews report 2021
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/interviewers-report-december-2020";
              }}
            >
              Interviewers report December 2020
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/registration";
              }}
            >
              New Registration
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/administrator-applications";
              }}
            >
              All Applications
            </MenuItem>
          </Menu>
        </>
      ) : (
        ""
      )}

      {Access.includes("Agent") || Access.includes("Marketeer") ? (
        <>
          <Button
            aria-controls="simple-menu"
            color="inherit"
            aria-haspopup="true"
            onClick={handleApplicationClick}
            style={style}
            startIcon={<Storage />}
          >
            Applications
          </Button>
          <Menu id="simple-menu" anchorEl={ApplicationAnchor} keepMounted open={Boolean(ApplicationAnchor)} onClose={handleApplicationClose}>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/registration";
              }}
            >
              Add Application
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/applications";
              }}
            >
              View Applications
            </MenuItem>
          </Menu>
        </>
      ) : (
        ""
      )}

      {Access.includes("Marketeer") ? (
        <>
          <Button
            aria-controls="simple-menu"
            color="inherit"
            aria-haspopup="true"
            onClick={handleMarketeerClick}
            style={style}
            startIcon={<Person />}
          >
            Marketeers
          </Button>
          <Menu id="simple-menu" anchorEl={MarketeerAnchor} open={Boolean(MarketeerAnchor)} onClose={handleMarketeerClose}>
            <MenuItem
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Add Appointment
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/appointments";
              }}
            >
              All Appointments
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/appointment-reports";
              }}
            >
              Reports
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/appointments-reports-2021";
              }}
            >
              Reports 2021
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "/#/dashboard/appointment-reports-december-2020";
              }}
            >
              December 2020 Reports
            </MenuItem>
          </Menu>
        </>
      ) : (
        ""
      )}
      <Button
        style={style}
        size="small"
        color="inherit"
        onClick={() => {
          window.location.href = "/#/dashboard/change-password";
        }}
        startIcon={<ViewAgenda></ViewAgenda>}
      >
        Change Password
      </Button>
      <Button
        style={style}
        size="small"
        onClick={() => {
          window.location.href = "/#/";
        }}
        startIcon={<ExitToApp></ExitToApp>}
        color="inherit"
      >
        Logout
      </Button>
    </Toolbar>
  );
};

export default NavigationComponent;
