import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box, TableBody, Button } from "@material-ui/core";
import { Delete, Refresh } from "@material-ui/icons";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import __ from "lodash";
import axios from "axios";

import AirtableBase from "../airtable";

const ViewAppointmentComponent = ({
  Marketeer,
  InterviewStudent,
  GetAppointments,
  setBackDropOpen,
}) => {
  const NormalizePhone = (phone) => {
    phone = phone.replace(/[^0-9]/g, "");
    const length = phone.length;
    if (length === 10) {
      return "+44" + phone;
    }
    if (length === 11) {
      return "+44" + phone.slice(-10);
    }
    return phone;
  };
  const HandleConfirm = async (data) => {
    setBackDropOpen(true);
    const html = `Dear ${data.name},<br><br>Thank you for expressing your interest in studying the <strong>${data.programme}/${data.university}</strong> with the London School of Commerce.<br><br>We are delighted to confirm that you have been invited to take part in a 'Zoom' interview for your admission onto the <strong>${data.programme}/${data.university}</strong>. Details of your interview are as follows:<br><br>Date: <strong>${data.date}</strong><br>Time: <strong>${data.time}</strong><br>Link: <strong><a href="${data.link}">${data.link}</a></strong><br><br>Please ensure that your camera and audio are activated. You may also need to download the ‘Zoom’ app/software (www.zoom.us), if you do not already have it installed. It may be helpful to view the following video, which explains how you join a zoom meeting <a href="https://www.youtube.com/watch?v=hIkCmbvAHQQ&feature=youtu.be">https://www.youtube.com/watch?v=hIkCmbvAHQQ&feature=youtu.be</a><br><br>Please note, all interviews are recorded for training and monitoring purposes.<br><br>Kind regards,<br>Admissions Department`;
    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
      {
        message: {
          to: data.email,
          name: "LSC Interviews",
          from: "interviews@lsclondon.co.uk",
          subject: "Invitation to ‘Zoom’ Interview: London School of Commerce",
          body: html,
        },
      }
    );
    await axios.post(
      "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/sms",
      {
        messages: [
          {
            source_address: "LSC",
            destination_address: NormalizePhone(data.phone),
            content: `Thank you for expressing your interest in studying the ${data.programme}/${data.university} with the London School of Commerce. Details of your interview are as follows:\nDate: ${data.date}\nTime: ${data.time}\nLink: ${data.link}\nPlease ensure that your camera and audio are activated.`,
          },
        ],
      }
    );
    if (data.agent) {
      await axios.post(
        "https://a60e5gszg1.execute-api.eu-west-2.amazonaws.com/email",
        {
          message: {
            to: data.agent,
            name: "LSC Interviews",
            from: "interviews@lsclondon.co.uk",
            subject:
              "Invitation to ‘Zoom’ Interview: London School of Commerce",
            body: html,
          },
        }
      );
    }
    AirtableBase("students").update(
      [
        {
          id: data.id,
          fields: {
            confirmed: true,
          },
        },
      ],
      (error, record) => {
        GetAppointments();
        setBackDropOpen(false);
      }
    );
  };

  const HandleDelete = async (name, id) => {
    if (
      window.confirm("Are you sure you want to delete " + name + " interview")
    ) {
      await AirtableBase("students").destroy(
        [id],
        function (err, deletedRecords) {
          if (err) {
            console.error(err);
            return;
          } else {
            GetAppointments();
          }
        }
      );
    }
  };

  const HandleUpload = async (id, file) => {
    setBackDropOpen(true);
    const formData = new FormData();
    formData.append("file", file);
    const upload = await axios.post(
      "https://0arwzgjox3.execute-api.eu-west-2.amazonaws.com/applications/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    AirtableBase("students").update(
      [
        {
          id: id,
          fields: {
            documents: upload.data.data.Location,
          },
        },
      ],
      (error, record) => {
        GetAppointments();
        setBackDropOpen(false);
      }
    );
  };

  const HandleStudentId = async (airtable, id) => {
    const student = prompt(
      "Enter the student ID provided by the university",
      id
    );
    if (student) {
      AirtableBase("students").update(
        [
          {
            id: airtable,
            fields: {
              "university id": student,
            },
          },
        ],
        (error, records) => {
          if (error) console.log(error);
          if (records) alert("Student ID updated");
          GetAppointments();
        }
      );
    }
  };

  const [Campuses] = useState(JSON.parse(localStorage.getItem("campuses")));
  const [Partners] = useState(JSON.parse(localStorage.getItem("partners")));
  const [Courses] = useState(JSON.parse(localStorage.getItem("courses")));
  const [Interviewer] = useState(
    JSON.parse(localStorage.getItem("interviewers"))
  );
  const [Slots] = useState(JSON.parse(localStorage.getItem("slots")));

  return (
    <Box mx="20px" my="84px">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {InterviewStudent.map((data, key) => {
              const campus = __.find(Campuses, { id: data.campus });
              const partner = __.find(Partners, { id: data.partner });
              const course = __.find(Courses, { id: data.course });
              const interviewer = __.find(Interviewer, {
                id: data.interviewer,
              });
              const slots = __.find(Slots, { id: data.slot });

              let feedback = "#f50057";
              if (data.feedback === "Approved") {
                feedback = "#155724";
              }
              return (
                <React.Fragment key={key}>
                  <TableRow border={0}>
                    <TableCell
                      rowSpan={3}
                      style={{ padding: 2, backgroundColor: feedback }}
                    ></TableCell>
                    <TableCell>
                      <strong>Full name: </strong>
                      {data.name}
                    </TableCell>
                    <TableCell>
                      <strong>Email: </strong>
                      {data.email}
                    </TableCell>
                    <TableCell>
                      <strong>Interviewer: </strong>
                      {interviewer.name}
                    </TableCell>
                    <TableCell>
                      <strong>Date: </strong>
                      {data.date}
                    </TableCell>
                    <TableCell>
                      <strong>Course: </strong>
                      {course.name}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "rgb(224 224 224)",
                        width: "150px",
                      }}
                    >
                      {data.interviewed ? (
                        <>
                          {data.feedback === "No Show" ||
                          data.feedback === "Invalid ID" ||
                          data.feedback === "Recall" ||
                          data.feedback === "Deferral" ||
                          data.feedback === "Rejected and Deferred" ? (
                            <>
                              {data.rebook ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                  startIcon={<Refresh />}
                                  disabled
                                >
                                  Rebooked
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                  startIcon={<Refresh />}
                                  onClick={() => {
                                    window.location.href =
                                      "/#/dashboard/rebook/" + data.id;
                                  }}
                                >
                                  Rebook
                                </Button>
                              )}
                            </>
                          ) : data.pqp_feedback ? (
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                            >
                              {data.pqp_feedback}
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        [
                          data.confirmed ? (
                            <Button
                              key="BUTTONCONFIRM"
                              variant="contained"
                              onClick={() => {
                                HandleConfirm({
                                  name: data.name,
                                  programme: course.name,
                                  university: partner.name,
                                  date: data.date,
                                  time: slots.time,
                                  phone: data.phone,
                                  link: interviewer.zoom,
                                  email: data.email,
                                  agent: data.agentEmail,
                                  id: data.id,
                                });
                              }}
                              size="small"
                              color="primary"
                              startIcon={<CheckCircleOutlineOutlinedIcon />}
                            >
                              Confirm Again
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              key="BUTTONCONFIRMED"
                              color="secondary"
                              startIcon={<CheckCircleOutlineOutlinedIcon />}
                              onClick={() => {
                                HandleConfirm({
                                  name: data.name,
                                  programme: course.name,
                                  university: partner.name,
                                  date: data.date,
                                  time: slots.time,
                                  phone: data.phone,
                                  link: interviewer.zoom,
                                  email: data.email,
                                  agent: data.agentEmail,
                                  id: data.id,
                                });
                              }}
                            >
                              Confirm
                            </Button>
                          ),
                        ]
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Code: </strong>
                      {data.code}
                    </TableCell>
                    <TableCell>
                      <strong>Phone: </strong>
                      {data.phone}
                    </TableCell>
                    <TableCell>
                      <strong>Agent: </strong>
                      {data.agent}
                    </TableCell>
                    <TableCell>
                      <strong>Time: </strong>
                      {slots ? slots.time : ""}
                    </TableCell>
                    <TableCell>
                      <strong>Campus: </strong>
                      {partner.name}, {campus.name}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "rgb(224 224 224)" }}>
                      {data["Approved by TA"] ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                        >
                          {data["group name"]}
                        </Button>
                      ) : (
                        ""
                      )}

                      {data.interviewed ? (
                        <></>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={() => {
                            HandleDelete(data.name, data.id);
                          }}
                          startIcon={<Delete />}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow border={0}>
                    <TableCell
                      colSpan={4}
                      style={{ backgroundColor: "#ededed" }}
                    >
                      {data.interviewed ? (
                        <>
                          Interviewer's recommendation{" "}
                          <strong style={{ color: feedback }}>
                            {data.feedback}:{" "}
                          </strong>
                          {data.comments}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell style={{ width: "350px" }}>
                      {data["Approved by TA"] ? (
                        <Button
                          style={{ color: "#ffffff", backgroundColor: "green" }}
                          variant="contained"
                          size="small"
                        >
                          Approved by Management
                        </Button>
                      ) : (
                        ""
                      )}
                      {data["Rejected by TA"] ? (
                        <Button
                          style={{ color: "#ffffff", backgroundColor: "black" }}
                          variant="contained"
                          size="small"
                        >
                          Rejected by Management
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "rgb(224 224 224)" }}>
                      {data["Approved by TA"] &&
                      partner.name === "University of Bedfordshire" ? (
                        <>
                          <Button
                            style={{
                              color: "#ffffff",
                              backgroundColor: "blue",
                            }}
                            variant="contained"
                            size="small"
                            onClick={() =>
                              HandleStudentId(data.id, data["university id"])
                            }
                          >
                            {data["university id"]
                              ? data["university id"]
                              : "Add Student ID"}
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {data.documents ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          href={data.documents}
                          target="_blank"
                          startIcon={<CloudDownloadIcon />}
                        >
                          View ID
                        </Button>
                      ) : (
                        <>
                          <input
                            type="file"
                            id={`file${data.id}`}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              HandleUpload(data.id, e.target.files[0]);
                            }}
                          />
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => {
                              document.getElementById(`file${data.id}`).click();
                            }}
                            target="_blank"
                            startIcon={<CloudDownloadIcon />}
                          >
                            Upload ID
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: "#ccc" }}>
                    <TableCell colSpan={7} style={{ padding: 4 }}></TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ViewAppointmentComponent;
