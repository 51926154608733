import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AirtableBase from "../airtable";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Moment from "moment";
import __ from "lodash";

export default ({ Marketeer, setAlertOpen, setAlertType, setAlertMessage, setBackDropOpen, GetAppointments }) => {
  const { getstudent } = useParams();
  const [Student, setStudent] = useState({});
  const [Interviewer, setInterviewer] = useState([]);

  const [Time, setTime] = useState([]);
  const [TimeDisable, setTimeDisable] = useState(true);

  const [DateDisable, setDateDisable] = useState(true);
  const [DatePickerMaxDate, setDatePickerMaxDate] = useState(new Date());
  const [DatePickerMinDate, setDatePickerMinDate] = useState(new Date());

  const [InterviewerStorage] = useState(JSON.parse(localStorage.getItem("interviewers")));

  const [DisableDateArray, setDisableDateArray] = useState([]);
  const [DisableBothDateArray, setDisableBothDateArray] = useState([]);
  const [DisableBothTimeArray, setDisableBothTimeArray] = useState([]);

  const [Appointment, setAppointment] = useState({
    Interviewer: "",
    Date: new Date(),
    Time: "",
  });

  const [AppointmentError, setAppointmentError] = useState({
    Interviewer: false,
    Date: false,
    Time: false,
  });

  useEffect(() => {
    AirtableBase("students").find(getstudent, async (err, record) => {
      const student = record.fields;
      setStudent({
        ID: getstudent,
        Code: student["code"],
        FirstName: student["first name"],
        LastName: student["last name"],
        EmailAddress: student["email"],
        PhoneNumber: student["phone"],
        Agent: student["agent"],
        Marketeer: student["marketeer"],
        Campus: student["campus"][0],
        Partner: student["partner"],
        Course: student["course"],
        Date: student["date"],
        Interviewer: student["interviewer"],
        Documents: student["documents"],
        Time: student["time"],
        AgentEmailAddress: student["agent email address"],
      });
    });
  }, [getstudent]);

  useEffect(() => {
    AirtableBase("interviewer")
      .select()
      .firstPage((err, records) => {
        if (err) console.error(err);
        setInterviewer(records);
      });
  }, [Student]);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const flag = await HandleValidation("", true);

    if (flag === 0) {
      await AirtableBase("students").create(
        [
          {
            fields: {
              code: Student.Code,
              "first name": Student.FirstName,
              "last name": Student.LastName,
              email: Student.EmailAddress,
              phone: Student.PhoneNumber,
              agent: Student.Agent,
              marketeer: Student.Marketeer,
              campus: [Student.Campus],
              partner: Student.Partner,
              course: Student.Course,
              date: Appointment.Date,
              interviewer: [Appointment.Interviewer],
              documents: Student.Documents,
              time: [Appointment.Time],
              "agent email address": Student.AgentEmailAddress,
              "Old Rebook Ref": [getstudent],
            },
          },
        ],
        (err, records) => {
          console.log(records);
          AirtableBase("students").update(
            [
              {
                id: getstudent,
                fields: {
                  Rebook: true,
                  "Rebook Ref": [records[0].id],
                },
              },
            ],
            (error, records) => {
              setAlertType("success");
              setAlertMessage("Rebook appointment successfully added");
              setAlertOpen(true);
              setBackDropOpen(false);
              setAppointment({
                Interviewer: "",
                Date: new Date(),
                Time: "",
              });
              GetAppointments();
              window.location.href = "/#/dashboard/appointments";
            }
          );
        }
      );
    }
  };

  const HandleValidation = (field, form) => {
    let flag = 0;
    if (field === "Interviewer" || form) {
      if (Appointment.Interviewer === "") {
        flag++;
        HandleSetState(setAppointmentError, "Interviewer", true);
      } else {
        HandleSetState(setAppointmentError, "Interviewer", false);
      }
    }

    if (field === "Date" || form) {
      const today = new Date();
      const selection = Appointment.Date;
      if (selection === today) {
        flag++;
        HandleSetState(setAppointmentError, "Date", true);
      } else {
        HandleSetState(setAppointmentError, "Date", false);
      }
    }

    if (field === "Time" || form) {
      if (Appointment.Time === "") {
        flag++;
        HandleSetState(setAppointmentError, "Time", true);
      } else {
        HandleSetState(setAppointmentError, "Time", false);
      }
    }
    return flag;
  };

  const HandleDateChange = async (e) => {
    setBackDropOpen(true);
    HandleSetState(setAppointment, "Time", "");
    setTimeDisable(true);
    setTime([]);
    HandleSetState(setAppointment, "Date", e);
    const mydate = `${e.getFullYear()}-${e.getMonth() + 1}-${e.getDate()}`;
    const days = {
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    };
    const day = days[Moment(e).day()];
    const interviewer = __.find(InterviewerStorage, { id: Appointment.Interviewer });
    const idate = Moment(e).format("YYYY/MM/DD");
    await AirtableBase("students")
      .select({
        view: "Grid view",
        filterByFormula: `AND({interviewer email} = '${interviewer.email}', {date} = DATETIME_PARSE('${idate}','YYYY/MM/DD'))`,
      })
      .firstPage(async (err, records) => {
        const unaavailable = [];
        await records.forEach((record) => {
          unaavailable.push({ id: record.fields.time[0], time: record.fields["interview time"][0] });
        });
        await AirtableBase("slots")
          .select({
            view: "Grid view",
            filterByFormula: `AND({available}, FIND("${day}", days) > 0)`,
          })
          .firstPage(function (err, records) {
            let AllSlots = [];
            records.forEach(function (record) {
              if (!(DisableBothDateArray.includes(mydate) && DisableBothTimeArray.includes(record.id))) {
                AllSlots.push({ id: record.id, time: record.fields.time });
              }
            });
            AllSlots = AllSlots.filter((o) => !unaavailable.find((o2) => o.id === o2.id));
            setTime(AllSlots);
            setTimeDisable(false);
            setBackDropOpen(false);
          });
      });
  };

  const HandleChange = (e) => {
    HandleSetState(setAppointment, e.target.name, e.target.value);
  };
  const HandleBlur = (event) => {
    HandleValidation(event.target.name, false);
  };

  const HandleSetState = (state, key, value) => {
    state((data) => ({ ...data, [key]: value }));
  };

  const HandleInterviewerChange = (e) => {
    if (e.target.value !== "") {
      setBackDropOpen(true);
      HandleChange(e);

      HandleSetState(setAppointment, "Date", new Date());
      HandleSetState(setAppointment, "Time", "");

      setDateDisable(true);
      setTimeDisable(true);

      setTime([]);

      AirtableBase("interviewer").find(e.target.value, function (err, record) {
        HandleSetState(setAppointment, "Date", new Date(record.fields.available_from));
        setDatePickerMinDate(record.fields.available_from);
        setDatePickerMaxDate(record.fields.available_to);
        if ("not available date" in record.fields) {
          if ("not available time" in record.fields) {
            const not_available_dates = record.get("not available date");
            setDisableBothDateArray(not_available_dates.split(","));
            setDisableBothTimeArray(record.get("not available time"));
            setDisableDateArray([]);
          } else {
            const not_available_dates = record.get("not available date");
            setDisableDateArray(not_available_dates.split(","));
            setDisableBothDateArray([]);
            setDisableBothTimeArray([]);
          }
        } else {
          setDisableBothDateArray([]);
          setDisableBothTimeArray([]);
          setDisableDateArray([]);
        }
        setBackDropOpen(false);
        setDateDisable(false);
      });
    }
  };

  return (
    <Box mx="0" my="100px">
      <Container maxWidth="lg">
        <Typography variant="h5">
          Rebooking of {Student.FirstName} {Student.LastName}
        </Typography>
        <br></br>
        <br></br>
        <form onSubmit={HandleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth error={AppointmentError.Interviewer}>
                <InputLabel id="select-interviewer-label">Select the interviewer</InputLabel>
                <Select
                  labelId="select-interviewer-label"
                  name="Interviewer"
                  value={Appointment.Interviewer}
                  onChange={HandleInterviewerChange}
                  onBlur={HandleBlur}
                  label="Select the interviewer"
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  {Interviewer.map((record, index) => {
                    if (record.fields.campus) {
                      if (record.fields.campus.includes(Student.Campus)) {
                        return (
                          <MenuItem value={record.id} key={index}>
                            {record.fields.name}
                          </MenuItem>
                        );
                      } else {
                        return <></>;
                      }
                    } else {
                      return <></>;
                    }
                  })}
                </Select>
                <FormHelperText>Please select the interviewer</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disablePast
                  value={Appointment.Date}
                  onChange={HandleDateChange}
                  onBlur={() => {
                    HandleValidation("Date", false);
                  }}
                  onClose={() => {
                    HandleValidation("Date", false);
                  }}
                  format="dd/MM/yyyy"
                  color="secondary"
                  fullWidth
                  helperText="Please select the date of interview"
                  inputVariant="outlined"
                  label="Date of interview"
                  shouldDisableDate={(date) => {
                    const mydate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                    return DisableDateArray.includes(mydate);
                  }}
                  maxDate={DatePickerMaxDate}
                  minDate={DatePickerMinDate}
                  disabled={DateDisable}
                  error={AppointmentError.Date}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth disabled={TimeDisable} error={AppointmentError.Time}>
                <InputLabel id="time-label">Select the time</InputLabel>
                <Select labelId="time-label" name="Time" value={Appointment.Time} label="time" onChange={HandleChange} onBlur={HandleBlur}>
                  <MenuItem value="">&nbsp;</MenuItem>
                  {Time.map((value, key) => {
                    return (
                      <MenuItem key={key} value={value.id}>
                        {value.time}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Please select the time of interview</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};
