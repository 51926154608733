import React from "react";
import { Box, Grid, Typography, Container, Button } from "@material-ui/core";
import { CSVLink } from "react-csv";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from "react";
import Moment from "moment";

import AirtableBase from "../airtable";

const CapitalizeWords = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const SalaryNormalize = (str) => {
  if (str) {
    if (str.includes("-") || str.includes("Upto")) {
      return `(${str})`;
    } else {
      str = str.replace(/£/g, "");
      str = str.replace(/,/g, "");
      const num = parseInt(str) / 1000;
      return `(£${num}k)`;
    }
  } else {
    return "";
  }
};

export default () => {
  const [From, setFrom] = useState(new Date());
  const [To, setTo] = useState(new Date());

  const [Data, setData] = useState([]);
  const [Coloumn] = useState([
    {
      key: "cell1",
      label: "Date of Interview",
    },
    {
      key: "cell2",
      label: "Interview by",
    },
    {
      key: "cell3",
      label: "University",
    },
    {
      key: "cell4",
      label: "Location",
    },
    {
      key: "cell5",
      label: "Name",
    },
    {
      key: "cell6",
      label: "Surname",
    },
    {
      key: "cell7",
      label: "Ethinicity",
    },
    {
      key: "cell8",
      label: "Job (incl Sal)",
    },
    {
      key: "cell9",
      label: "Commitment (100)",
    },
    {
      key: "cell10",
      label: "Maturity and stability (100)",
    },
    {
      key: "cell11",
      label: "Quality of spoken English (100)",
    },
    {
      key: "cell12",
      label: "Quality of written English (100)",
    },
    {
      key: "cell13",
      label: "Narrative (100)",
    },
    {
      key: "cell14",
      label: "Average Score (100)",
    },
    {
      key: "cell15",
      label: "Age",
    },
    {
      key: "cell16",
      label: "ROW #",
    },
    {
      key: "cell17",
      label: "Final Grade (A,B,C,F)",
    },
    {
      key: "cell18",
      label: "Recommend? (Y/N)",
    },
    {
      key: "cell19",
      label: "Comments",
    },
  ]);

  const downloadData = () => {
    setData([]);
    const students = [];
    AirtableBase("students")
      .select({
        filterByFormula: `AND({interviewed}, {date}>'${Moment(From).format("YYYY-MM-DD")}', {date}<'${Moment(To)
          .add(1, "days")
          .format("YYYY-MM-DD")}')`,
        sort: [
          { field: "date", direction: "asc" },
          { field: "interviewer", direction: "asc" },
        ],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(async (record) => {
            const status = record.get("feedback");
            if (status === "Approved" || status === "Rejected" || status === "Awaiting English Test") {
              let cell1 = new Date(record.get("date"));
              const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
              let recommendation = "";
              if (record.get("FinalGrade") === "A" || record.get("FinalGrade") === "B") {
                recommendation = "Y";
              }
              const averageScore =
                (parseInt(record.get("Commitment")) +
                  parseInt(record.get("MaturityAndStability")) +
                  parseInt(record.get("QualityOfSpokenEnglish")) +
                  parseInt(record.get("QualityOfWrittenEnglish")) +
                  parseInt(record.get("Narrative"))) /
                5;
              students.push({
                cell1: cell1.getDate() + "-" + month[cell1.getMonth()],
                cell2: record.get("interviewer initials")[0],
                cell3: record.get("partner initials")[0],
                cell4: record.get("location initials")[0],
                cell5: CapitalizeWords(record.get("first name")),
                cell6: CapitalizeWords(record.get("last name")),
                cell7: record.get("Ethnicity"),
                cell8: `${CapitalizeWords(record.get("JobTitle"))} ${SalaryNormalize(record.get("Salary"))}`,
                cell9: record.get("Commitment"),
                cell10: record.get("MaturityAndStability"),
                cell11: record.get("QualityOfSpokenEnglish"),
                cell12: record.get("QualityOfWrittenEnglish") ? record.get("QualityOfWrittenEnglish") : "NS",
                cell13: record.get("Narrative"),
                cell14: averageScore,
                cell15: record.get("Age"),
                cell16: record.get("number") ? record.get("number") : "",
                cell17: record.get("FinalGrade"),
                cell18: recommendation,
                cell19: record.get("comments"),
              });
            }
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          setData(students);
        }
      );
  };

  return (
    <Box mx="20px" my="84px">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Download Reports</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={From}
                onChange={(date) => {
                  setFrom(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="From"
                maxDate={new Date()}
                minDate={new Date("2020-07-01")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={To}
                onChange={(date) => {
                  setTo(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="To"
                maxDate={new Date()}
                minDate={new Date("2020-07-01")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="outlined" color="secondary" size="large" onClick={downloadData}>
              Generate Report
            </Button>
          </Grid>
          {Data.length > 0 ? (
            <Grid item xs={12} sm={5}>
              <CSVLink data={Data} headers={Coloumn} filename={"report.csv"} target="_blank">
                <Button variant="outlined" color="secondary" size="large">
                  Download Report
                </Button>
              </CSVLink>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </Box>
  );
};
