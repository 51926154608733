import React from "react";
import { Box, Grid, Typography, Container, Button } from "@material-ui/core";
import { CSVLink } from "react-csv";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from "react";
import Moment from "moment";

import AirtableBase from "../airtable";

const CapitalizeWords = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export default () => {
  const [From, setFrom] = useState(new Date());
  const [To, setTo] = useState(new Date());

  const [Data, setData] = useState([]);
  const [Coloumn] = useState([
    {
      key: "cell0",
      label: "Code",
    },
    {
      key: "cell1",
      label: "interview date",
    },
    {
      key: "cell2",
      label: "ROW",
    },
    {
      key: "cell3",
      label: "batch",
    },
    {
      key: "cell4",
      label: "First name",
    },
    {
      key: "cell5",
      label: "Last name",
    },
    {
      key: "cell6",
      label: "Email address",
    },
    {
      key: "cell7",
      label: "Phone number",
    },
    {
      key: "cell8",
      label: "Marketer",
    },
    {
      key: "cell9",
      label: "Agent",
    },
    {
      key: "cell10",
      label: "Course",
    },
    {
      key: "cell11",
      label: "Campus",
    },
    {
      key: "cell12",
      label: "Partner",
    },
    {
      key: "cell13",
      label: "Interviewer",
    },
    {
      key: "cell14",
      label: "Attendance 1",
    },
    {
      key: "cell15",
      label: "Attendance 2",
    },
    {
      key: "cell16",
      label: "Attendance 3",
    },
    {
      key: "cell17",
      label: "Commitment",
    },
    {
      key: "cell18",
      label: "Maturity and Stability",
    },
    {
      key: "cell19",
      label: "Quality of spoken english",
    },
    {
      key: "cell20",
      label: "Minimum 50",
    },
    {
      key: "cell21",
      label: "Minimum 40",
    },
    {
      key: "cell22",
      label: "Comprehension",
    },
    {
      key: "cell23",
      label: "Summarising",
    },
    {
      key: "cell24",
      label: "Critical thinking",
    },
    {
      key: "cell25",
      label: "Minimum 50",
    },
    {
      key: "cell26",
      label: "Minimum 40",
    },
    {
      key: "cell27",
      label: "Total Assessment",
    },
    {
      key: "cell28",
      label: "IP Address",
    },
    {
      key: "cell29",
      label: "PQP Comments",
    },
    {
      key: "cell30",
      label: "PQP Feedback",
    },
  ]);

  const downloadData = () => {
    setData([]);
    const students = [];
    AirtableBase("students")
      .select({
        filterByFormula: `AND({pqp checked}, {pqp done date}>'${Moment(From).format("YYYY-MM-DD")}', {pqp done date}<'${Moment(To)
          .add(1, "days")
          .format("YYYY-MM-DD")}')`,
        sort: [
          { field: "date", direction: "asc" },
          { field: "interviewer", direction: "asc" },
        ],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(async (record) => {
            const status = record.get("feedback");
            if (status === "Approved" || status === "Rejected" || status === "Awaiting English Test") {
              let cell1 = new Date(record.get("pqp done date"));
              let pqp_result = record.get("pqp result");
              const cell14 = pqp_result.split(",");
              const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

              students.push({
                cell0: record.get("code"),
                cell1: cell1.getDate() + "-" + month[cell1.getMonth()],
                cell2: record.get("number"),
                cell3: record.get("batch"),
                cell4: CapitalizeWords(record.get("first name")),
                cell5: CapitalizeWords(record.get("last name")),
                cell6: record.get("email"),
                cell7: record.get("phone"),
                cell8: record.get("marketeer name"),
                cell9: record.get("agent"),
                cell10: record.get("programme name"),
                cell11: record.get("location name")[0],
                cell12: record.get("partner name")[0],
                cell13: record.get("interviewer name")[0],
                cell14: "",
                cell15: "",
                cell16: "",
                cell17: record.get("Commitment"),
                cell18: record.get("MaturityAndStability"),
                cell19: record.get("QualityOfSpokenEnglish"),
                cell20: "",
                cell21: "",
                cell22: cell14[0],
                cell23: cell14[1],
                cell24: cell14[2],
                cell25: "",
                cell26: "",
                cell27: "",
                cell28: record.get("assessment_ip_address"),
                cell29: record.get("pqp comments"),
                cell30: record.get("pqp feedback"),
              });
            }
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          setData(students);
        }
      );
  };

  return (
    <Box mx="20px" my="84px">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Download Reports</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={From}
                onChange={(date) => {
                  setFrom(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="From"
                maxDate={new Date()}
                minDate={new Date("2020-07-01")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={To}
                onChange={(date) => {
                  setTo(date);
                }}
                format="dd/MM/yyyy"
                color="secondary"
                fullWidth
                helperText="Please select the date"
                inputVariant="outlined"
                label="To"
                maxDate={new Date()}
                minDate={new Date("2020-07-01")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="outlined" color="secondary" size="large" onClick={downloadData}>
              Generate Report
            </Button>
          </Grid>
          {Data.length > 0 ? (
            <Grid item xs={12} sm={5}>
              <CSVLink data={Data} headers={Coloumn} filename={"report.csv"} target="_blank">
                <Button variant="outlined" color="secondary" size="large">
                  Download Report
                </Button>
              </CSVLink>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Container>
    </Box>
  );
};
